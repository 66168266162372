import { isBoolean } from "lodash";
//getWarningText
export default (type) => {
    if (isBoolean(type)) {
        return "An issue has been detected.";
    }

    let value = "";
    if (type.includes("-")) {
        [type, value] = type.split("-");
    }

    return {
        compression:
            "The insulation you have selected will be compressed in the framing cavity, resulting in a lower R-Value than expected.",
        slabSkirtBreakZero: "Please enter a non-zero value to avoid calculation errors.",
        strappingZero: "Please enter a non-zero value to avoid calculation errors.",
        steelGaugeZero: "Please enter a non-zero value to avoid calculation errors.",
        floorsAboveZeroRVal: "Please enter a non-zero value to avoid calculation errors.",
        ponyHeightWarning:
            "Pony wall height + wall depth below grade must be less than the total basement wall height. Calculation errors may occur even when the sum is exactly equal to the total basement wall height.",
        ponyWallSubCompAreaWarning:
            "The total subcomponent area is greater than the exposed pony wall area, which will produce a HOT2000 calculation error. Consider moving some windows and doors onto a main floor wall component.",
        compositeZeroRValue:
            "Total R-value cannot calculate properly if any R-value inputs are 0 for sections with given areas.",
        compositeSingleZeroRValue: "R-value cannot be 0 while area percentage is non-zero.",
        compositeAreaFrac: "Total input area percentages are greater than 100%.",
        dhwNoBasement:
            "No basement components have been defined for this house. This selection will produce a calculation error.",
        supplHeatingNoBasement:
            "No basement components have been defined for this house. This selection will produce a calculation error.",
        floorAreaUnder10m2:
            "The total heated floor area is less than 10m² (108 ft²), this may produce a calculation or submission error.",
        basementNoBgFloorArea:
            "A basement is present but the below grade floor area is set to 0m², this may produce a calculation or submission error.",
        zeroTotalVolume: "The total house volume must be non-zero before running a simulation.",
        heatedCrawlspaceNoVolume:
            "A heated crawlspace is defined but is not included in the heated volume of the home. Ensure that both the 'Includes crawlspace volume' checkbox in the edit volume drawer and the 'Heated crawlspace' checkbox in the Temperatures tab are equal to allow simulations to proceed.",
        oilHeatingLowEff:
            "The efficiency for an oil heating system should be at least 80% for a house of the specified vintage, this may produce a calculation or submission error.",
        energyStarAshpLowHspf: "The specified HSPF is too low for an Energy Star ASHP.",
        orhModeLocationMismatch: "The location must be set to Ontario when Ontario Reference House mode is specified.",
        hpwhWorkaroundError:
            "A heat pump water heater must be modelled as an integrated heat pump, this may produce calculation or submission errors.",
        noFoundationExpFloor:
            "A model must contain either a foundation component or an exposed floor, this may produce calculation or submission errors.",
        basementWallHeightLowerLimit:
            "The minimum permitted basement wall height is 1.204m (3.95ft), this may produce calculation or submission errors.",
        largeExpFoundationPerimeter:
            "The exposed perimeter must not exceed the total perimeter of the foundation component.",
        ceilingAreaVsFoundationArea:
            "The total ceiling area is greater than the total foundation and exposed floor area, this will require a manual submission with a justification.",
        ersUSpecRVal:
            "A User Specified R-value is not permitted in ERS or ORH mode, this may result in a submission error.",
        ceilingInsLow1990:
            "The specified ceiling insulation is too low for a house built after 1990, this will require a manual submission with a justification.",
        noWindowPresent:
            "A house model must contain at least one window, this may produce calculation or submission errors.",
        condensingHeatingAfue:
            "When modelling a condensing heating system the AFUE must be used, this may produce calculation or submission errors.",
        highWindowER: "This value appears to be too high, please ensure it is correct.",
        minFoundationPerimeter: `This perimeter is too small to contain the specified area. The perimeter should be at least ${value}`,
        highBedroomCountNBC:
            'For more than 5 bedrooms, see CAN/CSA-F326-M91 (R2010) "Residential Mechanical Ventilation Systems"',
        highBedroomCountOBC: "For more than 5 bedrooms, system must comply with OBC Sentence 6.2.1.1.(1)",
        invalidWindowFrame: "Please select a compatible window frame option",
        invalidWindowType: "Please select a compatible window type option",
        f280WeatherImport:
            "This value is an estimate. Double check that the correct F280 weather location is selected before running a simulation",
        blowerDoorVolumeSync:
            "For this test type, the overall house volume displayed on the enclosure tab will be overwritten with the sum of the heated zone volumes input below.",
        noConnectedMurbDwhrUnits: "At least one unit must be connected for this housing type.",
        nonErsUserProgramSelection:
            "Simulations and file exports are disabled while in this simulation mode. If you are a registered energy advisor you may request access to unlock this feature.",
        nonResFloorAreaCountMismatch:
            "Enter a non-zero number of non-residential units in the General/MURB Unit Details section.",
        murbUnitMismatch:
            "The number of units specified does not align with the building type: total dwelling units + non-res units must be at least 2.",
        default: "An issue has been detected.",
    }[type || "default"];
};
