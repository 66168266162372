import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getOptions, getBaseUnits, getUnitOptions } from "utils/fields";
import roofCavityInputsTemplate from "../templates/roofCavityInputs.json";

import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import InputWithUnits from "components/Input/InputWithUnits";
import Button from "components/Button";
import Checkbox from "components/Input/Checkbox";

const thermalMassValidation = getValidation("thermalMass");
const intWindowShadingValidation = getValidation("interiorWindowShading");
const massFracValidation = getValidation("effectiveMassFraction");
const soilValidation = getValidation("soilCondition");
const waterLevelValidation = getValidation("waterLevel");
const wallColourValidation = getValidation("wallColour");
const roofColourValidation = getValidation("roofColour");

const gableAreaValidation = getValidation("roofCavityGableArea");
const roofCavityGableSheathingValidation = getValidation("roofCavityGableSheathing");
const roofCavityGableExteriorValidation = getValidation("roofCavityGableExterior");

const slopedAreaValidation = getValidation("roofCavitySlopedArea");
const roofCavitySlopedSheathingValidation = getValidation("roofCavitySlopedSheathing");
const roofCavitySlopedRoofingMatValidation = getValidation("roofCavitySlopedRoofingMat");

const roofCavityVolumeValidation = getValidation("roofCavityVolume");
const roofCavityVentilationValidation = getValidation("roofCavityVentilationRate");

export default React.memo(
    ({
        accessor,
        modelUnits,
        isDefaultRoofInputs,
        h2kVersion,
        h2kMinor,
        wallColourId,
        roofColourId,
        wallColourValue,
        roofColourValue,
        roofCavityGableAreaUnits,
        roofCavityGableAreaEquation,
        gableSheathingMaterialRValue,
        gableExteriorMaterialRValue,
        gableSheathingMaterialId,
        gableExteriorMaterialId,
        gableSheathingMaterialRValueUnits,
        gableExteriorMaterialRValueUnits,
        roofCavitySlopedAreaUnits,
        roofCavitySlopedAreaEquation,
        slopedSheathingMaterialRValue,
        slopedRoofingMaterialRValue,
        slopedSheathingMaterialId,
        slopedRoofingMaterialId,
        slopedSheathingMaterialRValueUnits,
        slopedRoofingMaterialRValueUnits,
        roofCavityVolumeUnits,
        roofCavityVolumeEquation,
        change,
    }) => (
        <>
            <InputRow gridTemplate="2fr 1fr 2fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.thermalMass`}
                    options={getOptions({ fieldKey: "thermalMass" })}
                    label="Thermal Mass"
                    placeholder="Choose Thermal Mass"
                    validate={thermalMassValidation}
                />
                <Field
                    component={Input}
                    type="text"
                    name={`${accessor}.effectiveMassFraction`}
                    label="Effective Mass Fraction"
                    placeholder="0.00"
                    validate={massFracValidation}
                    change={change}
                />
                <Field
                    component={Select}
                    name={`${accessor}.interiorWindowShading`}
                    options={getOptions({ fieldKey: "interiorWindowShading" })}
                    label="Interior Window Shading"
                    placeholder="None"
                    validate={intWindowShadingValidation}
                    nonH2kParam="f280IntWindowShading"
                />
            </InputRow>
            <InputRow gridTemplate="2fr 2fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.soilCondition`}
                    options={getOptions({ fieldKey: "soilCondition" })}
                    label="Foundation Soil Condition"
                    placeholder="Choose Soil Conductivity"
                    validate={soilValidation}
                    info={
                        'If unsure, select "Normal". If a slab is constructed on permafrost, this should be recorded. However, if a house in a permafrost area is built on piers, select "Normal"'
                    }
                />
                <Field
                    component={Select}
                    name={`${accessor}.waterLevel`}
                    options={getOptions({ fieldKey: "waterLevel" })}
                    label="Water Table Level"
                    placeholder="Choose Water Table Level"
                    validate={waterLevelValidation}
                    info={'Unless official documentation states otherwise, use the default value of "Normal".'}
                />
            </InputRow>
            <InputRow gridTemplate="2fr 1fr 2fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.wallColour`}
                    options={[
                        {
                            label: "User Specified",
                            value: {
                                id: 0,
                                value: wallColourValue, //need to get rValue for this to match
                            },
                        },
                        ...getOptions({ fieldKey: "wallColour" }),
                    ]}
                    label="Wall Colour"
                    placeholder="Choose Wall Colour"
                    validate={wallColourValidation}
                    info={
                        "Only model the exterior wall colour if over 50% of the wall is uninsulated. Note that log homes are not considered uninsulated."
                    }
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.wallColour.value`}
                    label="Absorptance"
                    placeholder={0.0}
                    disabled={wallColourId !== 0}
                    validate={wallColourValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces("wallColour")}
                    change={change}
                    setValue={wallColourId !== 0 ? wallColourValue : null}
                />
                <Field
                    component={Select}
                    name={`${accessor}.roofColour`}
                    options={[
                        {
                            label: "User Specified",
                            value: {
                                id: 0,
                                value: roofColourValue, //need to get rValue for this to match
                            },
                        },
                        ...getOptions({ fieldKey: "roofColour" }),
                    ]}
                    label="Roof Colour"
                    placeholder="Choose Roof Colour"
                    validate={roofColourValidation}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.roofColour.value`}
                    label="Absorptance"
                    placeholder={0.0}
                    disabled={roofColourId !== 0}
                    validate={roofColourValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces("roofColour")}
                    change={change}
                    setValue={roofColourId !== 0 ? roofColourValue : null}
                />
            </InputRow>
            <InputRow gridTemplate="2fr 1fr 2fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.isMixedUse`}
                    label="Is Mixed Use?"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
                <div></div>
                {h2kMinor >= 11 && (
                    <Field
                        component={Checkbox}
                        name={`${accessor}.eligibleForNBC`}
                        label="Eligible for NBC Compliance"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                    />
                )}
                <div></div>
            </InputRow>

            <InputRow gridTemplate="2fr 1fr 2fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.isDefaultRoofCavity`}
                    label="Default Roof Cavity Inputs"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                    onChange={() => {
                        if (isDefaultRoofInputs && gableSheathingMaterialId == null) {
                            //indicates that section is empty
                            change(`${accessor}.roofCavity`, roofCavityInputsTemplate);
                        }
                    }}
                />
            </InputRow>
            {!isDefaultRoofInputs && (
                <div className={classes.roofInputsContainer}>
                    <h5>Roof Cavity Inputs: Gable Ends</h5>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.roofCavity.gableEnds.area`}
                            label="Total Area"
                            placeholder="ENTER VALUE"
                            validate={gableAreaValidation}
                            decimals={getDecimalPlaces("roofCavityGableArea")}
                            change={change}
                            units={{
                                base: getBaseUnits("roofCavityGableArea", modelUnits),
                                options: getUnitOptions("area"),
                                selected: roofCavityGableAreaUnits,
                                unitType: "area",
                                accessor: `${accessor}.roofCavity.gableEnds.area_u`,
                            }}
                            withEquation
                            equation={roofCavityGableAreaEquation}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={Select}
                            name={`${accessor}.roofCavity.gableEnds.sheathingMaterial`}
                            options={[
                                {
                                    label: "User Specified",
                                    value: {
                                        id: 0,
                                        value: gableSheathingMaterialRValue, //need to get rValue for this to match
                                    },
                                },
                                ...getOptions({ fieldKey: "roofCavityGableSheathing" }),
                            ]}
                            label="Sheathing Material"
                            placeholder="Choose Sheathing Material"
                            validate={roofCavityGableSheathingValidation}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.roofCavity.gableEnds.sheathingMaterial.value`}
                            label="R-Value"
                            placeholder={0.0}
                            disabled={gableSheathingMaterialId !== 0}
                            validate={roofCavityGableSheathingValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("roofCavityGableSheathing")}
                            change={change}
                            setValue={gableSheathingMaterialId !== 0 ? gableSheathingMaterialRValue : null}
                            units={{
                                base: getBaseUnits("roofCavityGableSheathing", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: gableSheathingMaterialRValueUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.roofCavity.gableEnds.sheathingMaterial_u`,
                            }}
                        />
                        <Field
                            component={Select}
                            name={`${accessor}.roofCavity.gableEnds.exteriorMaterial`}
                            options={[
                                {
                                    label: "User Specified",
                                    value: {
                                        id: 0,
                                        value: gableExteriorMaterialRValue, //need to get rValue for this to match
                                    },
                                },
                                ...getOptions({ fieldKey: "roofCavityGableExterior" }),
                            ]}
                            label="Exterior Material"
                            placeholder="Choose Exterior Material"
                            validate={roofCavityGableExteriorValidation}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.roofCavity.gableEnds.exteriorMaterial.value`}
                            label="R-Value"
                            placeholder={0.0}
                            disabled={gableExteriorMaterialId !== 0}
                            validate={roofCavityGableExteriorValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("roofCavityGableExterior")}
                            change={change}
                            setValue={gableExteriorMaterialId !== 0 ? gableExteriorMaterialRValue : null}
                            units={{
                                base: getBaseUnits("roofCavityGableExterior", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: gableExteriorMaterialRValueUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.roofCavity.gableEnds.exteriorMaterial_u`,
                            }}
                        />
                    </InputRow>

                    <h5>Roof Cavity Inputs: Sloped Roof</h5>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.roofCavity.slopedRoof.area`}
                            label="Total Area"
                            placeholder="ENTER VALUE"
                            validate={slopedAreaValidation}
                            decimals={getDecimalPlaces("roofCavitySlopedArea")}
                            change={change}
                            units={{
                                base: getBaseUnits("roofCavitySlopedArea", modelUnits),
                                options: getUnitOptions("area"),
                                selected: roofCavitySlopedAreaUnits,
                                unitType: "area",
                                accessor: `${accessor}.roofCavity.slopedRoof.area_u`,
                            }}
                            withEquation
                            equation={roofCavitySlopedAreaEquation}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={Select}
                            name={`${accessor}.roofCavity.slopedRoof.sheathingMaterial`}
                            options={[
                                {
                                    label: "User Specified",
                                    value: {
                                        id: 0,
                                        value: slopedSheathingMaterialRValue, //need to get rValue for this to match
                                    },
                                },
                                ...getOptions({ fieldKey: "roofCavitySlopedSheathing" }),
                            ]}
                            label="Sheathing Material"
                            placeholder="Choose Sheathing Material"
                            validate={roofCavitySlopedSheathingValidation}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.roofCavity.slopedRoof.sheathingMaterial.value`}
                            label="R-Value"
                            placeholder={0.0}
                            disabled={slopedSheathingMaterialId !== 0}
                            validate={roofCavitySlopedSheathingValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("roofCavitySlopedSheathing")}
                            change={change}
                            setValue={slopedSheathingMaterialId !== 0 ? slopedSheathingMaterialRValue : null}
                            units={{
                                base: getBaseUnits("roofCavitySlopedSheathing", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: slopedSheathingMaterialRValueUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.roofCavity.slopedRoof.sheathingMaterial_u`,
                            }}
                        />
                        <Field
                            component={Select}
                            name={`${accessor}.roofCavity.slopedRoof.roofingMaterial`}
                            options={[
                                {
                                    label: "User Specified",
                                    value: {
                                        id: 0,
                                        value: slopedRoofingMaterialRValue, //need to get rValue for this to match
                                    },
                                },
                                ...getOptions({ fieldKey: "roofCavitySlopedRoofingMat" }),
                            ]}
                            label="Roofing Material"
                            placeholder="Choose Roofing Material"
                            validate={roofCavitySlopedRoofingMatValidation}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.roofCavity.slopedRoof.roofingMaterial.value`}
                            label="R-Value"
                            placeholder={0.0}
                            disabled={slopedRoofingMaterialId !== 0}
                            validate={roofCavitySlopedRoofingMatValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("roofCavitySlopedRoofingMat")}
                            change={change}
                            setValue={slopedRoofingMaterialId !== 0 ? slopedRoofingMaterialRValue : null}
                            units={{
                                base: getBaseUnits("roofCavitySlopedRoofingMat", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: slopedRoofingMaterialRValueUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.roofCavity.slopedRoof.roofingMaterial_u`,
                            }}
                        />
                    </InputRow>
                    <h5>Roof Cavity Additional Inputs</h5>
                    <InputRow gridTemplate="2fr 1fr 2fr 1fr">
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.roofCavity.volume`}
                            label="Cavity Volume"
                            placeholder="ENTER VALUE"
                            validate={roofCavityVolumeValidation}
                            decimals={getDecimalPlaces("roofCavityVolume")}
                            change={change}
                            units={{
                                base: getBaseUnits("roofCavityVolume", modelUnits),
                                options: getUnitOptions("volume"),
                                selected: roofCavityVolumeUnits,
                                unitType: "volume",
                                accessor: `${accessor}.roofCavity.volume_u`,
                            }}
                            withEquation
                            equation={roofCavityVolumeEquation}
                        />
                        <div></div>
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.roofCavity.ventilationRate`}
                            label="Ventilation Rate"
                            placeholder={0.0}
                            validate={roofCavityVentilationValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("roofCavityVentilationRate")}
                            change={change}
                            units={{
                                base: getBaseUnits("roofCavityVentilationRate", modelUnits),
                                options: getUnitOptions("airtightness"),
                                selected: "ACH50",
                                unitType: "airtightness",
                                accessor: `${accessor}.roofCavity.ventilationRate_u`,
                            }}
                        />
                        <div></div>
                    </InputRow>
                </div>
            )}
        </>
    )
);
