import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import AirConditioning from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model" }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const currentPrimarySystem = Object.keys(selector({ form }, `${accessor}.system`) || {})[0];
    const { id: equipTypeId } = selector({ form }, `${accessor}.system.airConditioning.equipmentType`) || {};
    const { id: capacityId, value: capacityValue } =
        selector({ form }, `${accessor}.system.airConditioning.specifications.outputCapacity`) || {};

    const capacityUnits = selector({ form }, `${accessor}.system.airConditioning.specifications.outputCapacity_u`);
    const crankcaseHeaterUnits = selector({ form }, `${accessor}.system.airConditioning.crankcaseHeater_u`);
    const openableWindowAreaUnits = selector(
        { form },
        `${accessor}.system.airConditioning.specifications.coolingParams.openableWindowArea_u`
    );

    const { minor: h2kMinor } = modelSelector({ form }, "modelData.h2kVersion") || {};

    return {
        accessor,
        currentPrimarySystem,
        equipTypeId,
        capacityId,
        capacityValue,
        capacityUnits,
        crankcaseHeaterUnits,
        openableWindowAreaUnits,
        modelUnits,
        h2kMinor,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AirConditioning);
