import React from "react";
import classes from "./style.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import { getValidation } from "utils/fieldValidation";
import { getOptions } from "utils/fields";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import DayPicker from "components/Input/DayPicker";

const fileIdValidation = getValidation("fileID");
const prevFileIdValidation = getValidation("prevFileID");
const enrollmentValidation = getValidation("houseEnrollmentID");
const homeAuthIdValidation = getValidation("homeownerAuthID");
const ownershipValidation = getValidation("ownershipType");
const ownerOccupiedValidation = getValidation("ownerOccupied");
const propertyTaxValidation = getValidation("propertyTaxNumber");
const builderNameValidation = getValidation("builderName");
const userNameValidation = getValidation("userNameEnteredBy");
const userPhoneValidation = getValidation("userTelephone");
const userPhoneExtValidation = getValidation("userExtension");
const userCompanyValidation = getValidation("userCompany");
const companyPhoneValidation = getValidation("companyTelephone");
const companyExtValidation = getValidation("companyExtension");

//TODO: Check %'s on third and fourth input row
export default React.memo(({ accessor, modelUnits, username, h2kMinor, change }) => (
    <>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Checkbox}
                name={`${accessor}.nameplateEfficiency`}
                label="Efficiency from nameplate"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field
                component={Checkbox}
                name={`${accessor}.combustionTestEfficiency`}
                label="Efficiency from combustion test"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field
                component={Checkbox}
                name={`${accessor}.heatingCorrection`}
                label="Heating system correction"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Checkbox}
                name={`${accessor}.achCorrection`}
                label="ACH correction"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field
                component={Checkbox}
                name={`${accessor}.twoBlowerDoors`}
                label="Two blower doors used"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field
                component={Checkbox}
                name={`${accessor}.over18Months`}
                label="18+ months"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr"></InputRow>
        <InputRow gridTemplate="1fr 1fr">
            <Field
                component={Checkbox}
                name={`${accessor}.possessionDate_active`}
                label="Possession date"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field
                component={DayPicker}
                formatting="YYYY-MM-DD"
                name={`${accessor}.possessionDate`}
                label="Date"
                disabledDays={{ before: null, after: null }}
            />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr">
            <Field
                component={Checkbox}
                name={`${accessor}.heatingVolumeDecrease_active`}
                label="Heating volume decrease"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field component={Input} name={`${accessor}.heatingVolumeDecrease`} large />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr">
            <Field
                component={Checkbox}
                name={`${accessor}.correctedInsCeilings_active`}
                label="Corrected insulation value in ceilings"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field component={Input} name={`${accessor}.correctedInsCeilings`} large />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr">
            <Field
                component={Checkbox}
                name={`${accessor}.correctedInsWalls_active`}
                label="Corrected insulation value in walls"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field component={Input} name={`${accessor}.correctedInsWalls`} large />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr">
            <Field
                component={Checkbox}
                name={`${accessor}.correctedInsBasement_active`}
                label="Corrected insulation value in basement"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field component={Input} name={`${accessor}.correctedInsBasement`} large />
        </InputRow>
        <InputRow gridTemplate="1fr 1fr">
            <Field
                component={Checkbox}
                name={`${accessor}.other_active`}
                label="Other"
                large
                className={classes.bottomCheckBox}
                type="checkbox"
            />
            <Field component={Input} name={`${accessor}.other`} large />
        </InputRow>
    </>
));
