import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import HouseFileInfo from "./";

const mapStateToProps = ({ form, user: { name: username } }, { accessor, h2kMinor }) => {
    const selector = formValueSelector("model");
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        modelUnits,
        username,
        h2kMinor,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HouseFileInfo);
