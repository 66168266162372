import React from "react";
import uniqid from "uniqid";

import WallConstruction from "features/Model/Enclosure/ComponentList/Wall/Construction/container.js";
import WindowConstruction from "features/Model/Enclosure/ComponentList/Window/Construction/container.js";
import CeilingConstruction from "features/Model/Enclosure/ComponentList/Ceiling/Construction/container.js";
import ExpFloorConstruction from "features/Model/Enclosure/ComponentList/ExpFloor/Construction/container.js";
import BasementWallConstruction from "features/Model/Enclosure/ComponentList/Basement/WallConstruction/container.js";
import BasementFloorConstruction from "features/Model/Enclosure/ComponentList/Basement/FloorConstruction/container.js";
import BasementPonyWallConstruction from "features/Model/Enclosure/ComponentList/Basement/PonyWall/container.js";
import CrawlspaceWallConstruction from "features/Model/Enclosure/ComponentList/Crawlspace/WallConstruction/container.js";
import CrawlspaceFloorConstruction from "features/Model/Enclosure/ComponentList/Crawlspace/FloorConstruction/container.js";
import SlabFloorConstruction from "features/Model/Enclosure/ComponentList/Slab/FloorConstruction/container.js";
import DoorConstruction from "features/Model/Enclosure/ComponentList/Door/Construction/container.js";
import FloorHeaderConstruction from "features/Model/Enclosure/ComponentList/FloorHeader/Construction/container.js";
import Loan from "../../features/Model/General/Financing/Loan/container";
import Grant from "../../features/Model/General/Financing/Grant/container";
import FinancialValuation from "../../features/Model/General/Financing/FinancialValuation/container";

import HeatingSystems from "features/Model/Mechanicals/HeatingSystems/container";
import CoolingHeatPumps from "features/Model/Mechanicals/CoolingHeatPumps/container";
import VentilationSystems from "features/Model/Mechanicals/Ventilation/container";
import AirtightnessComp from "features/Model/Airtightness/container";
import GenerationComp from "features/Model/Mechanicals/Generation/container";
import HotWaterSystems from "features/Model/Mechanicals/HotWater/container";

import ElectricalUsage from "features/Model/Mechanicals/BaseLoads/ElectricalUsage/container";
import WaterUsage from "features/Model/Mechanicals/BaseLoads/WaterUsage/container";

import airTightnessDefaults from "./templates/airTightness.json";
import baseLoadsDefaults from "./templates/baseLoads.json";
import domesticHotWaterDefaults from "./templates/domesticHotWater.json";
import generationDefaults from "./templates/generation.json";
import heatingCoolingDefaults from "./templates/heatingCooling.json";
import ventilationDefaults from "./templates/ventilation.json";

import { ReactComponent as WallIcon } from "assets/images/components/Wall.svg";
import { ReactComponent as BasementIcon } from "assets/images/components/Foundation-Basement.svg";
import { ReactComponent as CeilingIcon } from "assets/images/components/Ceiling.svg";
import { ReactComponent as CrawlspaceIcon } from "assets/images/components/Foundation-Crawlspace.svg";
import { ReactComponent as DoorIcon } from "assets/images/components/Door.svg";
import { ReactComponent as FloorIcon } from "assets/images/components/ExpFloor.svg";
import { ReactComponent as FloorHeaderIcon } from "assets/images/components/FloorHeader.svg";
import { ReactComponent as SlabIcon } from "assets/images/components/Foundation-Slab.svg";
import { ReactComponent as WindowIcon } from "assets/images/components/Window.svg";
import { ReactComponent as GenerationIcon } from "assets/images/icons/Generation.svg";
import { ReactComponent as HeatingIcon } from "assets/images/icons/Tab-Additional.svg";
import { ReactComponent as AirIcon } from "assets/images/icons/Tab-Air.svg";
import { ReactComponent as HotWaterIcon } from "assets/images/icons/Icon-HotWater.svg";
import { ReactComponent as VentilationIcon } from "assets/images/icons/Icon-Ventilation.svg";

import pvPotential from "utils/weather/pvPotential.json";
import { energyConversion } from "utils/constants/energyConversion";

import cleanComponents from "utils/cleanComponents";

import { isEmpty } from "lodash";

import { getOptions, getOptionsWithSimpleLabel } from "utils/fields";
import { getNetTotalEnergyConsumption } from "utils/results";

import { getPcf2004ProposedEmissions, getPcf2004RefEmissions } from "utils/emissions/getEmissionFactors";
import { provinceCodeMap } from "utils/weather";
import { getEuiComplianceTier, getNbc2020Tier } from "utils/buildingCodesPrograms";
import { getChbaNzAlternateCompliance } from "utils/buildingCodesPrograms/chbaNz";
import { getCompAreaForCostCalc } from "utils/enclosure/components";

const formatNumDisplay = (num, decimals) => {
    return num.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
};

export const upgradeOptionsComponents = [
    {
        label: "Basement",
        value: "basement",
        singleSelect: true,
        icon: BasementIcon,
    },
    {
        label: "Basement",
        value: "basementWall",
        singleSelect: true,
        icon: BasementIcon,
    },
    {
        label: "Basement",
        value: "basementSlab",
        singleSelect: true,
        icon: BasementIcon,
    },
    {
        label: "Ceiling",
        value: "ceiling",
        singleSelect: true,
        icon: CeilingIcon,
    },
    {
        label: "Ceilings with Attic Space",
        value: "ceilingWithAttic",
        singleSelect: true,
        icon: CeilingIcon,
    },
    {
        label: "Cathedral Ceilings",
        value: "cathedral",
        singleSelect: true,
        icon: CeilingIcon,
    },
    {
        label: "Crawlspace",
        value: "crawlspace",
        singleSelect: true,
        icon: CrawlspaceIcon,
    },
    {
        label: "Door",
        value: "door",
        icon: DoorIcon,
    },
    {
        label: "Exposed Floor",
        value: "expFloor",
        icon: FloorIcon,
    },
    {
        label: "Floor Header",
        value: "floorHeader",
        icon: FloorHeaderIcon,
    },
    {
        label: "Slab-on-grade",
        value: "slab",
        singleSelect: true,
        icon: SlabIcon,
    },
    {
        label: "Wall",
        value: "wall",
        icon: WallIcon,
    },
    {
        label: "Window",
        value: "window",
        icon: WindowIcon,
    },
    {
        label: "Skylights",
        value: "skylights",
        icon: WindowIcon,
    },
];

export const upgradeOptionsSystems = [
    {
        label: "Heating and Cooling",
        value: "heatingCooling",
        icon: HeatingIcon,
    },
    {
        label: "Heating System",
        value: "spaceHeating",
        icon: HeatingIcon,
    },
    {
        label: "Cooling & Heat Pumps",
        value: "spaceCooling",
        icon: HeatingIcon,
    },
    {
        label: "Secondary Heating",
        value: "secondaryHeating",
        icon: HeatingIcon,
    },
    {
        label: "Ventilation",
        value: "ventilation",
        icon: VentilationIcon,
    },
    {
        label: "Hot Water",
        value: "domesticHotWater",
        icon: HotWaterIcon,
    },
    {
        label: "Hot Water",
        value: "primaryHotWater",
        icon: HotWaterIcon,
    },
    {
        label: "Hot Water",
        value: "dwhr",
        icon: HotWaterIcon,
    },
    {
        label: "Generation",
        value: "generation",
        icon: GenerationIcon,
    },
    {
        label: "Airtightness",
        value: "airTightness",
        icon: AirIcon,
    },
    {
        label: "Base Loads",
        value: "baseLoads",
        icon: GenerationIcon,
    },
    {
        label: "Base Loads",
        value: "bathroomFaucets",
        icon: GenerationIcon,
    },
    {
        label: "Base Loads",
        value: "showerHeads",
        icon: GenerationIcon,
    },
    {
        label: "Base Loads",
        value: "clothesWasher",
        icon: GenerationIcon,
    },
    {
        label: "Base Loads",
        value: "dishwasher",
        icon: GenerationIcon,
    },
    {
        label: "Base Loads",
        value: "lighting",
        icon: GenerationIcon,
    },
    {
        label: "Base Loads",
        value: "monitoringSys",
        icon: GenerationIcon,
    },
];

export const upgradeOptionsHvac = [];

export const getComponentOptions = (component, componentCats, currentUpgrades, selectedUpgrade) =>
    Object.keys(componentCats)
        .reduce((cache, key) => {
            // get id and location
            const cleanedComps = cleanComponents(componentCats);
            const components = cleanedComps[key] || {};

            const componentArray = Object.keys(components).map((componentId) => {
                const { label = "" } = components[componentId];
                const value = `${key}.${componentId}`;

                // do any existing upgrades already reference this component?
                const existingComponent = Object.keys(currentUpgrades).some((key) => {
                    // Ignore those in this particular upgrade
                    if (key === selectedUpgrade) {
                        return false;
                    }

                    const { selectedComponents = [] } = currentUpgrades[key] || {};
                    return selectedComponents.includes(value);
                });

                return {
                    label,
                    value, // TODO: update for subcomponents
                    disabled: existingComponent,
                };
            });

            return [...cache, ...componentArray];
        }, [])
        .filter(({ value = "" }) => value.includes(component));

//Only call this with 'component' === 'window' 'door' or 'floorHeader'
//TODO: Verify that 'disabled' works as expected
export const getSubComponentOptions = (component, componentCats, currentUpgrades, selectedUpgrade) =>
    Object.keys(componentCats)
        .reduce((cache, key) => {
            const cleanedComps = cleanComponents(componentCats);
            //this returns a flat array with value (accessor) and label of subcomponents

            const parentComp = cleanedComps[key] || {};
            const subcomponents = Object.keys(parentComp).reduce((compCache, parentKey) => {
                const { subcomponents: { [component]: subComps = {}, door: doorObj = {} } = {} } =
                    parentComp[parentKey];
                return [
                    ...compCache,
                    ...Object.keys(subComps).reduce((subCache, subCompKey) => {
                        return [
                            ...subCache,
                            {
                                value: `${key}.${parentKey}.subcomponents.${component}.${subCompKey}`,
                                label: subComps[subCompKey].label,
                            },
                        ];
                    }, []),
                    ...(component === "window" ? getWindowsOnDoors(doorObj, `${key}.${parentKey}.subcomponents`) : []),
                ];
            }, []);

            const subComponentArray = subcomponents.map((subcomp) => {
                const { label = "", value = "" } = subcomp;

                // do any existing upgrades already reference this component?
                const existingComponent = Object.keys(currentUpgrades).some((key) => {
                    // Ignore those in this particular upgrade
                    if (key === selectedUpgrade) {
                        return false;
                    }

                    const { selectedComponents = [] } = currentUpgrades[key] || {};
                    return selectedComponents.includes(value);
                });

                return {
                    label,
                    value,
                    disabled: existingComponent,
                };
            });

            return [...cache, ...subComponentArray];
        }, [])
        .filter(({ value = "" }) => value.includes(component));

const getWindowsOnDoors = (doorsObj, currentPath) => {
    return Object.keys(doorsObj).reduce((cache, doorKey) => {
        const { label: doorLabel = "", subcomponents: { window = {} } = {} } = doorsObj[doorKey];
        return [
            ...cache,
            ...Object.keys(window).reduce((subCache, subCompKey) => {
                return [
                    ...subCache,
                    {
                        value: `${currentPath}.door.${doorKey}.subcomponents.window.${subCompKey}`,
                        label: `${window[subCompKey].label} (Door: ${doorLabel})`,
                    },
                ];
            }, []),
        ];
    }, []);
};

export const stripWindowSkylights = (availableComponents, upgradeType) => {
    return availableComponents.filter((comp) => {
        const { value: accessor = "" } = comp;
        if (upgradeType === "skylights") {
            return accessor.includes("ceiling");
        } else if (upgradeType === "window") {
            return !accessor.includes("ceiling");
        }
    });
};

export const stripCeilings = (availableComponents, componentCats, upgradeType) => {
    return availableComponents.filter((comp) => {
        const { value: accessor = "" } = comp;
        const { constructionType: { id: constructionTypeId } = {} } = fieldSelector(componentCats, accessor);
        const isFlat = [2, 3].includes(constructionTypeId);
        if (upgradeType === "cathedral") {
            return isFlat;
        } else {
            return !isFlat;
        }
    });
};

export const getComponent = ({ componentPath, componentCats }) => {};

const BasementConstruction = (props) => (
    <>
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>Basement Wall</h5>
        <BasementWallConstruction {...props} />
        <BasementPonyWallConstruction {...props} />
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>Basement Floor</h5>
        <BasementFloorConstruction {...props} />
    </>
);

const CrawlspaceConstruction = (props) => (
    <>
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>Crawlspace Wall</h5>
        <CrawlspaceWallConstruction {...props} />
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>Crawlspace Floor</h5>
        <CrawlspaceFloorConstruction {...props} />
    </>
);

const HeatingCooling = (props) => (
    <>
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>Heating System</h5>
        <HeatingSystems {...props} />
        <h5
            style={{
                margin: "3rem 0 0.75rem",
                borderTop: "1px solid #e1eaf0",
                paddingTop: "2.5rem",
            }}
        >
            Cooling & Heat Pumps
        </h5>
        <CoolingHeatPumps {...props} />
    </>
);

const Heating = (props) => (
    <>
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>Heating System</h5>
        <HeatingSystems hideSecondaryHeating={true} {...props} />
    </>
);

const SecondaryHeating = (props) => (
    <>
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>Secondary Heating Systems</h5>
        <HeatingSystems hidePrimaryHeating={true} {...props} />
    </>
);

const Cooling = (props) => (
    <>
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>Cooling & Heat Pumps</h5>
        <CoolingHeatPumps {...props} />
    </>
);

const BaseLoads = ({ accessor, ...props }) => (
    <>
        <h3 style={{ margin: "1.5rem 0 0.75rem" }}>Water Usage</h3>
        <WaterUsage accessor={`${accessor}.waterUsage`} {...props} />
        <h3 style={{ margin: "3rem 0 0.75rem" }}>Electrical Usage</h3>
        <ElectricalUsage accessor={`${accessor}.electricalUsage`} {...props} />
        <h5 style={{ margin: "3rem 0 0.75rem", color: "#c61717" }}>
            Please note that a file cannot be run in ERS or ORH mode when base load upgrades are present.
        </h5>
        <h5 style={{ margin: "1.5rem 0 0.75rem" }}>
            In ERS or ORH mode, this screen only impacts the labels shown in the upgrades table, and changes here will{" "}
            <u>not</u> be reflected in simulation results. Use Reduced Operating Conditions to investigate the impacts
            of reducing base loads.
        </h5>
    </>
);

export const componentUpgradeContent = (component) => {
    const contentList = {
        wall: WallConstruction,
        ceiling: CeilingConstruction,
        ceilingWithAttic: CeilingConstruction,
        cathedral: CeilingConstruction,
        expFloor: ExpFloorConstruction,
        basement: BasementConstruction,
        basementSlab: BasementConstruction,
        basementWall: BasementConstruction,
        crawlspace: CrawlspaceConstruction,
        slab: SlabFloorConstruction,
        door: DoorConstruction,
        window: WindowConstruction,
        skylights: WindowConstruction,
        floorHeader: FloorHeaderConstruction,
        heatingCooling: HeatingCooling,
        spaceHeating: Heating,
        secondaryHeating: SecondaryHeating,
        spaceCooling: Cooling,
        airTightness: AirtightnessComp,
        generation: GenerationComp,
        ventilation: VentilationSystems,
        domesticHotWater: HotWaterSystems,
        primaryHotWater: HotWaterSystems,
        dwhr: HotWaterSystems,
        baseLoads: BaseLoads,
        bathroomFaucets: BaseLoads,
        showerHeads: BaseLoads,
        clothesWasher: BaseLoads,
        dishwasher: BaseLoads,
        lighting: BaseLoads,
        monitoringSys: BaseLoads,
        loan: Loan,
        grant: Grant,
        financialValuation: FinancialValuation,
    };

    return contentList[component] || null;
};

// Didn't need to do this - can probably remove
export const getAllEnvelopeUpgradeOptions = (components) => {
    return upgradeOptionsComponents.reduce((obj, { value }) => {
        const options = ["window", "door", "floorHeader"].includes(value)
            ? getSubComponentOptions(value, components, {}, null)
            : getComponentOptions(value, components, {}, null);

        return [...obj, ...options];
    }, []);
};

export const getUpgradeEnvelopeRowLabels = ({ components, codes, airtightness, rowId, upgradePackage = {} }) => {
    const { type: rowType = "" } = envelopeRowHeadings.find((el) => el.id === rowId) || {};

    const labelType = isEmpty(upgradePackage) ? "base" : "upgrade";

    if (rowId === "airTightness") {
        return getAirtightnessLabel({
            airtightness,
            upgradePackage,
            returnType: labelType,
        });
    } else if (rowId === "skylights") {
        //only pass ceiling components
        const { ceiling: ceilingComponents = {} } = components;
        return getEnvelopeCompLabel({
            data: {
                components: { ceiling: ceilingComponents },
                codes,
                upgradePackage,
            },
            component: rowType,
            returnType: labelType,
            type: "skylights",
        });
    } else if (rowId === "window") {
        //do not pass ceiling components
        const { ceiling = {}, ...restComponents } = components;
        return getEnvelopeCompLabel({
            data: { components: { ...restComponents }, codes, upgradePackage },
            component: rowType,
            returnType: labelType,
        });
    } else {
        return getEnvelopeCompLabel({
            data: { components, codes, upgradePackage },
            component: rowType,
            returnType: labelType,
        });
    }
};

export const getUpgradeHvacRowLabels = ({ heatingCooling, ventilation, rowId, upgradePackage = {} }) => {
    // const rowDetails = hvacRowHeadings.find(el => el.id === rowId)

    const labelType = isEmpty(upgradePackage) ? "base" : "upgrade";

    if (rowId === "ventilation") {
        return getVentilationLabel({
            ventilation,
            upgradePackage,
            returnType: labelType,
        }); //include "Bathroom and Kitchen Exhaust Fans"
    } else {
        return getHeatingCoolingLabel({
            heatingCooling,
            upgradePackage,
            rowId,
            returnType: labelType,
        });
    }
};

export const getUpgradeDhwRowLabels = ({ domesticHotWater, heatingCooling, rowId, upgradePackage = {} }) => {
    // const rowDetails = hvacRowHeadings.find(el => el.id === rowId)

    const labelType = isEmpty(upgradePackage) ? "base" : "upgrade";

    return getDhwLabel({
        domesticHotWater,
        heatingCooling,
        upgradePackage,
        rowId,
        returnType: labelType,
    });
};

// make a new function here for financing rows
export const getUpgradeOtherRowLabels = ({ generation, program, baseLoads, rowId, upgradePackage = {} }) => {
    // const rowDetails = hvacRowHeadings.find(el => el.id === rowId)
    const labelType = isEmpty(upgradePackage) ? "base" : "upgrade";

    if (rowId === "generation") {
        return getSolarPvLabel({
            generation,
            upgradePackage,
            returnType: labelType,
        });
    } else if (
        ["bathroomFaucets", "showerHeads", "clothesWasher", "dishwasher", "lighting", "monitoringSys"].includes(rowId)
    ) {
        return getBaseLoadsLabel({
            baseLoads,
            upgradePackage,
            returnType: labelType,
            rowId,
        });
    }

    return {
        label: "-",
        upgradeIds: [],
    };
};

export const getUpgradeFinancingRowsLabels = ({ rowId, upgradePackage = {} }) => {
    const labelType = isEmpty(upgradePackage) ? "base" : "upgrade";
    if (rowId === "loan") {
        return getLoanLabel(upgradePackage, labelType, rowId);
    } else if (rowId === "grant") {
        return getGrantLabel(upgradePackage, labelType, rowId);
    } else if (rowId === "financialValuation") {
        return getValuationParamsLabel(upgradePackage, labelType, rowId);
    } else if (["totalCapitalCost"].includes(rowId)) {
        const costLabel = labelType === "base" ? "-" : getTotalCapitalCost(upgradePackage);

        return {
            label: costLabel,
            upgradeIds: costLabel === "$0.00" ? [] : ["placeholder"],
        };
    }
    return {
        label: "-",
        upgradeIds: [],
    };
};

export const envelopeRowHeadings = [
    {
        id: "ceilingWithAttic",
        value: "ceilingWithAttic",
        type: "Ceiling",
        upgradeType: "ceiling",
        content: "Ceiling with Attic Space",
    },
    {
        id: "cathedral",
        value: "cathedral",
        type: "CeilingFlat",
        upgradeType: "ceiling",
        content: "Cathedral / Vault / Flat",
    },
    {
        id: "wall",
        value: "wall",
        type: "Wall",
        upgradeType: "wall",
        content: "Above Grade Walls / Garage Wall",
    },
    {
        id: "expFloor",
        value: "expFloor",
        type: "Floor",
        upgradeType: "expFloor",
        content: "Exposed Floors",
    },
    {
        id: "floorHeader",
        value: "floorHeader",
        type: "FloorHeader",
        upgradeType: "floorHeader",
        content: "Floor Headers",
    },
    {
        id: "basementWall",
        value: "basementWall",
        type: "BasementWall",
        upgradeType: "basement",
        content: "Foundation Wall",
    },
    {
        id: "basementSlab",
        value: "basementSlab",
        type: "FloorsAdded",
        upgradeType: "basement",
        content: "Under Basement Slab",
    },
    {
        id: "crawlspace",
        value: "crawlspace",
        type: "CrawlspaceWall",
        upgradeType: "crawlspace",
        content: "Crawlspace",
    },
    {
        id: "slab",
        value: "slab",
        type: "Slab",
        upgradeType: "slab",
        content: "Slab-on-grade",
    },
    {
        id: "window",
        value: "window",
        type: "Window",
        upgradeType: "window",
        content: "Windows & Sliding Glass Doors",
    },
    {
        id: "skylights",
        value: "skylights",
        type: "Window",
        upgradeType: "window",
        content: "Skylights",
    },
    {
        id: "door",
        value: "door",
        type: "Door",
        upgradeType: "door",
        content: "Doors",
    },
    {
        id: "airTightness",
        value: "airTightness",
        type: "Airtightness",
        upgradeType: "airTightness",
        content: "Airtightness",
    },
];

export const envelopePDFRowHeadings = [
    {
        id: "ceilingWithAttic",
        value: "ceilingWithAttic",
        type: "Ceiling",
        upgradeType: "ceiling",
        content: "Ceiling with Attic Space",
    },
    {
        id: "cathedral",
        value: "cathedral",
        type: "CeilingFlat",
        upgradeType: "ceiling",
        content: "Cathedral / Vault / Flat",
    },
    {
        id: "wall",
        value: "wall",
        type: "Wall",
        upgradeType: "wall",
        content: "Above Grade Walls / Garage Wall",
    },
    {
        id: "expFloor",
        value: "expFloor",
        type: "Floor",
        upgradeType: "expFloor",
        content: "Exposed Floors",
    },
    {
        id: "basementWall",
        value: "basementWall",
        type: "BasementWall",
        upgradeType: "basement",
        content: "Foundation Wall",
    },
    {
        id: "basementSlab",
        value: "basementSlab",
        type: "FloorsAdded",
        upgradeType: "basement",
        content: "Under Basement Slab",
    },
    {
        id: "window",
        value: "window",
        type: "Window",
        upgradeType: "window",
        content: "Windows & Sliding Glass Doors",
    },
    {
        id: "door",
        value: "door",
        type: "Door",
        upgradeType: "door",
        content: "Doors",
    },
    {
        id: "airTightness",
        value: "airTightness",
        type: "Airtightness",
        upgradeType: "airTightness",
        content: "Airtightness",
    },
];

export const simulationDetailRows = [
    {
        id: "operatingConditions",
        value: "Operating Conditions",
    },
    {
        id: "relativeBaseCase",
        value: "Base Case",
    },
];

export const simulationRowHeadings = [
    // {
    //     id: "version",
    //     value: "HOT2000 Version/Points",
    // },
    {
        id: "totalEnergyConsumption",
        value: "Total Energy Consumption (GJ)",
    },
    {
        id: "energyConsumptionReduction",
        value: "Energy Consumption Reduction",
    },
    {
        id: "conditioningDhwImprovement",
        value: "Space Conditioning & DHW Consumption Reduction",
    },
    {
        id: "grossHeatLoss",
        value: "Gross Heat Loss (GJ)",
    },
    {
        id: "auxiliaryEnergy",
        value: "Auxiliary Heating Energy Required (GJ)",
    },
    {
        id: "grossHeatLossImprovement",
        value: "Gross Heat Loss Improvement vs ERS Ref. House",
    },
    {
        id: "energyUseIntensity",
        value: "EUI (GJ/m²)", //total [GJ]/[m²]
    },
    {
        id: "thermalEnergyDemandIntensity",
        value: "TEDI (kWh/m²)", //auxenergy [kWh] / [m²]
    },
    {
        id: "mechanicalEnergyUseIntensity",
        value: "MEUI (kWh/m²)", // total - baseloads / m²
    },
    {
        id: "ghgEmissions",
        value: "Est. Operational GHG Emissions (t/y)",
    },
    {
        id: "ghgEmissionReduction",
        value: "Operational GHG Emission Reduction",
    },
    {
        id: "designHeatLoss20",
        value: "HOT2000 Design Heat Loss (BTU/h)",
    },
    {
        id: "designHeatGain31",
        value: "HOT2000 Design Heat Gain (BTU/h)",
    },
    {
        id: "solarPanelsRequired",
        value: "Est. Number of Solar Panels (350W)",
    },
    {
        id: "heatlossComfort",
        value: "Heat Loss Comfort Assessment \n(Recommended 10 or lower)",
    },

    // {
    //     id: "nbcThermalPerformance",
    //     value: "NBC 2020 Tiered Energy Code 9.36 Envelope Thermal Performance (%)",
    // },
    // {
    //     id: "nbcEnergyReduction",
    //     value: "NBC 2020 Tiered Energy Code 9.36 Total Annual Energy Reduction (%)",
    // },
];

export const simCodeComplianceHeadings = [
    {
        id: "nbc2020Tier",
        value: "NBC 2020 Performance Tier",
    },
    {
        id: "nbc2020OverallImprovement",
        value: "Overall Energy Performance Improvement vs ERS Ref.",
    },
    {
        id: "nbc2020HeatLossReduction",
        value: "Heat Loss Reduction vs ERS Ref.",
    },
    {
        id: "nbc2020PeakCoolingValidation",
        value: "Peak Cooling Validation (Prop. / Ref., W)",
    },
    {
        id: "pcf1869EnergyUseIntensity",
        value: "PCF 1869 - Energy Use Intensity Tier",
    },
    {
        id: "pcf2004PropEmissions",
        value: "PCF 2004 - Proposed Operational Emissions (t/y)",
    },
    {
        id: "pcf2004PerformanceLevel",
        value: "PCF 2004 - Operational Emissions Performance Level",
    },
];

export const chbaProgramComplianceHeadings = [
    {
        id: "alternateCompliancePassed",
        value: "NZr Alternative Compliance Path",
    },
];

export const simulationF280RowHeadings = [
    {
        id: "f280DesignHeatLoss",
        value: "Design Heat Loss (BTU/h)",
    },
    {
        id: "f280DesignHeatGain",
        value: "Design Heat Gain (BTU/h)",
    },
];

export const simulationF280PDFHeadings = [
    {
        id: "f280DesignHeatLoss",
        value: "F280-12 Design Heat Loss (BTU/h)",
    },
    {
        id: "f280DesignHeatGain",
        value: "F280-12 Design Heat Gain (BTU/h)",
    },
];

export const simulationDetailsPDFHeadings = [
    {
        id: "operatingConditions",
        value: "Operating Conditions",
    },
    {
        id: "relativeBaseCase",
        value: "Base Case",
    },
];

export const simulationEnergyUseHeadings = [
    {
        id: "primaryHeating",
        value: "Primary Space Heating",
        color: "#0049C6",
    },
    {
        id: "secondaryHeating",
        value: "Secondary Space Heating",
        color: "#62BCF8",
    },
    {
        id: "primaryDHW",
        value: "Primary DHW Heating",
        color: "#FFCE35",
    },
    {
        id: "secondaryDHW",
        value: "Secondary DHW Heating",
        color: "#E6772B",
    },
    {
        id: "lightsAppliances",
        value: "Lights & Appliances",
        color: "#D95328",
    },
    {
        id: "hrvFans",
        value: "HRV and Fans",
        color: "#D1495B",
    },
    {
        id: "airConditioner",
        value: "Air Conditioner",
        color: "#22A495",
    },
];

export const simulationBaseEnergyBreakdownBaseHeadings = [
    {
        id: "baseConsumption",
        value: "Baseline",
        color: "#65676A",
    },
];

export const simulationUpgradeEnergyBreakdownBaseHeadings = [
    {
        id: "upgradeConsumption",
        value: "All Upgrades",
        color: "#0049C6",
    },
];

export const simulationEnvelopeEnergyBreakdownHeadings = [
    {
        id: "socCeilings",
        value: "Ceiling with Attic Space",
        color: "#62BCF8",
    },
    {
        id: "socCeilingsCathedralFlat",
        value: "Cathedral Ceilings",
        color: "#62BCF8",
    },
    {
        id: "socWalls",
        value: "Walls",
        color: "#62BCF8",
    },
    {
        id: "socWindowsAll",
        value: "Windows",
        color: "#62BCF8",
    },
    {
        id: "socFoundation",
        value: "Foundation",
        color: "#62BCF8",
    },
    {
        id: "socAirTightness",
        value: "Airtightness",
        color: "#62BCF8",
    },
];

export const simulationHVACEnergyBreakdownHeadings = [
    {
        id: "socHeating",
        value: "Heating",
        color: "#22A495",
    },
    {
        id: "socCooling",
        value: "Cooling",
        color: "#22A495",
    },
    {
        id: "socVentilation",
        value: "Ventilation",
        color: "#22A495",
    },
];

export const simulationDHWEnergyBreakdownHeadings = [
    {
        id: "socHotWater",
        value: "Domestic Water Heater",
        color: "#FFCE35",
    },
];

export const simulationHeatlossHeadings = [
    {
        id: "ceilings",
        value: "Ceilings",
        color: "#0049C6",
    },
    {
        id: "mainWalls",
        value: "Main Walls",
        color: "#62BCF8",
    },
    {
        id: "doors",
        value: "Doors",
        color: "#FFCE35",
    },
    {
        id: "expFloors",
        value: "Exposed Floors",
        color: "#E6772B",
    },
    {
        id: "windows",
        value: "Windows",
        color: "#D95328",
    },
    {
        id: "foundation",
        value: "Foundation",
        color: "#D1495B",
    },
    {
        id: "ventilation",
        value: "Mechanical Ventilation & Air Infiltration",
        color: "#22A495",
    },
];

export const simulationFuelHeadings = [
    {
        id: "naturalGasGj",
        value: "Est. Natural Gas Consumption (GJ)",
    },
    {
        id: "electricityGj",
        value: "Est. Electricity Consumption (GJ)",
    },
    {
        id: "pvProductionGj",
        value: "Est. PV Electricity Production (GJ)",
    },
    {
        id: "propaneGj",
        value: "Est. Propane Consumption (GJ)",
    },
    {
        id: "oilGj",
        value: "Est. Oil Consumption (GJ)",
    },
    {
        id: "woodGj",
        value: "Est. Wood Consumption (GJ)",
    },
    {
        id: "naturalGasM3",
        value: "Est. Natural Gas Consumption (m³)",
    },
    {
        id: "electricityKwh",
        value: "Est. Electricity Consumption (kWh)",
    },
    {
        id: "pvProductionKwh",
        value: "Est. PV Electricity Production (kWh)",
    },
    {
        id: "propaneL",
        value: "Est. Propane Consumption (L)",
    },
    {
        id: "oilL",
        value: "Est. Oil Consumption (L)",
    },
    {
        id: "woodCord",
        value: "Est. Wood Consumption (cord)",
    },
];

export const simulationFuelPDFHeadings = [
    {
        id: "naturalGasM3",
        value: "Est. Natural Gas Consumption (m³)",
    },
    {
        id: "electricityKwh",
        value: "Est. Electricity Consumption (kWh)",
    },
    {
        id: "propaneL",
        value: "Est. Propane Consumption (L)",
    },
    {
        id: "oilL",
        value: "Est. Oil Consumption (L)",
    },
    {
        id: "woodCord",
        value: "Est. Wood Consumption (cord)",
    },
];

export const simulationCostHeadings = [
    {
        id: "operatingExpenses",
        value: "Est. Annual Operating Expenses ($/yr)",
    },
    {
        id: "operatingCostSavings",
        value: "Est. Percentage Op. Cost Savings",
    },
    {
        id: "annualNaturalGasCost",
        value: "Est. Annual Natural Gas Cost",
    },
    {
        id: "annualElectricityCost",
        value: "Est. Annual Electricity Cost",
    },
    {
        id: "annualPropaneCost",
        value: "Est. Annual Propane Cost",
    },
    {
        id: "annualOilCost",
        value: "Est. Annual Oil Cost",
    },
    {
        id: "annualWoodCost",
        value: "Est. Annual Wood Cost",
    },
    {
        id: "simplePayback",
        value: "Est. Simple Payback (y)",
    },
    {
        id: "annLoanCost",
        value: "Annual Capital Loan Cost",
    },
    {
        id: "totalCostOwnership",
        value: "Total Cost of Ownership",
    },
    {
        id: "netPresentValue",
        value: "Net Present Value",
    },
];

export const simulationCostPDFHeadings = [
    {
        id: "operatingExpenses",
        value: "Est. Annual Operating Expenses ($/yr)",
    },
    {
        id: "operatingCostSavings",
        value: "Est. Percentage Op. Cost Savings",
    },
    {
        id: "annualNaturalGasCost",
        value: "Est. Annual Natural Gas Cost",
    },
    {
        id: "annualElectricityCost",
        value: "Est. Annual Electricity Cost",
    },
    {
        id: "annualPropaneCost",
        value: "Est. Annual Propane Cost",
    },
    {
        id: "annualOilCost",
        value: "Est. Annual Oil Cost",
    },
    {
        id: "annualWoodCost",
        value: "Est. Annual Wood Cost",
    },
];

export const hvacRowHeadings = [
    {
        id: "ventilation",
        upgradeType: "ventilation",
        value: "ventilation",
        content: "Principle Ventilation",
    },
    {
        id: "spaceHeating",
        upgradeType: "heatingCooling",
        value: "spaceHeating",
        content: "Space Heating",
    },
    {
        id: "secondaryHeating",
        upgradeType: "heatingCooling",
        value: "secondaryHeating",
        content: "Secondary Heating/Fireplace",
    },
    {
        id: "spaceCooling",
        upgradeType: "heatingCooling",
        value: "spaceCooling",
        content: "Cooling & Heat Pumps",
    },
];

export const hvacRowPDFHeadings = [
    {
        id: "ventilation",
        upgradeType: "ventilation",
        value: "ventilation",
        content: "Principle Ventilation",
    },
    {
        id: "spaceHeating",
        upgradeType: "heatingCooling",
        value: "spaceHeating",
        content: "Space Heating",
    },
    {
        id: "spaceCooling",
        upgradeType: "heatingCooling",
        value: "spaceCooling",
        content: "Cooling & Heat Pumps",
    },
];

export const dhwRowHeadings = [
    {
        id: "primaryHotWater",
        value: "primaryHotWater",
        upgradeType: "domesticHotWater",
        content: "Domestic Water Heater",
    },
    {
        id: "dwhr",
        value: "dwhr",
        upgradeType: "domesticHotWater",
        content: "Drain Water Heat Recovery",
    },
];

export const otherRowHeadings = [
    {
        id: "generation",
        value: "generation",
        upgradeType: "generation",
        content: "On-site Generation and Storage",
    },
    {
        id: "bathroomFaucets",
        value: "bathroomFaucets",
        upgradeType: "baseLoads",
        content: "Bathroom Faucets",
    },
    {
        id: "showerHeads",
        value: "showerHeads",
        upgradeType: "baseLoads",
        content: "Shower Heads",
    },
    {
        id: "clothesWasher",
        value: "clothesWasher",
        upgradeType: "baseLoads",
        content: "Clothes Washer",
    },
    {
        id: "dishwasher",
        value: "dishwasher",
        upgradeType: "baseLoads",
        content: "Dishwasher",
    },
    {
        id: "lighting",
        value: "lighting",
        upgradeType: "baseLoads",
        content: "Lighting and Appliances",
    },
    {
        id: "monitoringSys",
        value: "monitoringSys",
        upgradeType: "baseLoads",
        content: "Energy Use Monitoring System",
        small: true,
    },
];

// export const grantRebateHeadings = [
//     {
//         id: "grant",
//         value: "grant",
//         content: "Grant(s)",
//     },
//     // {
//     //     id: "hrv",
//     //     value: "hrv",
//     //     content: "HRV",
//     // },
//     // {
//     //     id: "insAboveGradeWalls",
//     //     value: "insAboveGradeWalls",
//     //     content: "Insulation: above-grade walls",
//     // },
//     // {
//     //     id: "insBelowGradeWalls",
//     //     value: "insBelowGradeWalls",
//     //     content: "Insulation: below-grade walls",
//     // },
//     // {
//     //     id: "insSlabFloorSys",
//     //     value: "insSlabFloorSys",
//     //     content: "Insulation: slab / floor system",
//     // },
//     // {
//     //     id: "totalCapitalCost",
//     //     value: "ccASHP",
//     //     content: "ccASHP",
//     // },
//     // {
//     //     id: "solarPV",
//     //     value: "solarPV",
//     //     content: "Solar PV",
//     // },
//     // {
//     //     id: "evCharger",
//     //     value: "evCharger",
//     //     content: "Electric vehicle & electric vehicle charger",
//     // },
// ];

export const financingRowHeadings = [
    {
        id: "loan",
        value: "loan",
        content: "Loan(s)",
    },
    {
        id: "grant",
        value: "grant",
        content: "Grant(s)",
    },
    {
        id: "financialValuation",
        value: "financialValuation",
        content: "Valuation Parameters",
    },
    {
        id: "totalCapitalCost",
        value: "totalCapitalCost",
        content: "Total Capital Cost",
    },
];

export const otherRowPDFHeadings = [
    {
        id: "generation",
        value: "generation",
        upgradeType: "generation",
        content: "On-site Generation and Storage",
    },
    {
        id: "bathroomFaucets",
        value: "bathroomFaucets",
        upgradeType: "baseLoads",
        content: "Bathroom Faucets",
    },
    {
        id: "showerHeads",
        value: "showerHeads",
        upgradeType: "baseLoads",
        content: "Shower Heads",
    },
    {
        id: "clothesWasher",
        value: "clothesWasher",
        upgradeType: "baseLoads",
        content: "Clothes Washer",
    },
    {
        id: "dishwasher",
        value: "dishwasher",
        upgradeType: "baseLoads",
        content: "Dishwasher",
    },
    {
        id: "lighting",
        value: "lighting",
        upgradeType: "baseLoads",
        content: "Lighting and Appliances",
    },
    {
        id: "monitoringSys",
        value: "monitoringSys",
        upgradeType: "baseLoads",
        content: "Energy Use Monitoring System",
        small: true,
    },
];

const getEnvelopeCompLabel = ({ data = {}, component, type = "", returnType = "label" }) => {
    let upgradeKeys = [];
    if (isEmpty(data)) {
        return { label: "None", upgradeIds: upgradeKeys };
    }

    let { components = {}, codes = {}, upgradePackage = {} } = data;

    let codeDetails = {};

    //Get all code types that match component
    const compType = codeCompMap[component].comp;
    let accessors = ["Window", "FloorHeader", "Door"].includes(component)
        ? getAllSubcomponents(components, compType)
        : getAllComponents(components, compType);

    let windowDetails = {};

    // ================================== check for upgrades first ===================================//
    //Check for upgrade before doing things with baseline components
    //IF we're dealing with an upgrade case, we must search for an upgraded component of the given type
    let upgradeMatch = {};
    if (["upgrade"].includes(returnType)) {
        //search upgrade package
        const packageKey = Object.keys(upgradePackage)[0];
        const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

        if (component === "Ceiling" || component === "CeilingFlat") {
            upgradeMatch =
                Object.values(upgrades).filter((el, ind) => {
                    const { upgradeType = "", fields: { constructionType: { id } = {} } = {} } = el;
                    const isFlat = [2, 3].includes(id);
                    if (
                        upgradeType === compType &&
                        ((isFlat && component === "CeilingFlat") || (!isFlat && component === "Ceiling"))
                    ) {
                        upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                        return true;
                    }

                    return false;
                })[0] || {};
        } else {
            upgradeMatch =
                Object.values(upgrades).filter((el, ind) => {
                    const { upgradeType = "", selectedComponents = [], label = {} } = el;
                    const isSkylight = Object.keys(label).includes("skylights");
                    //Need this check for skylights vs regular windows
                    //With the addition of the upgrade package library, we bypass this section with the !isEmpty(components) term
                    let selectedCompMatch = true;

                    if (type === "skylights" && upgradeType === compType && !isEmpty(components?.ceiling || {})) {
                        //The selected components must contain at least one of the incoming accessors
                        selectedCompMatch = accessors.some((el) => selectedComponents.includes(el));
                    } else if (component === "Window" && upgradeType === compType && !isEmpty(components)) {
                        //The selected components must contain at least one of the incoming accessors
                        selectedCompMatch = accessors.some((el) => selectedComponents.includes(el));
                    }

                    //Should only return when in upgradeLibrary because of isEmpty
                    if (type === "skylights" && isSkylight && isEmpty(components?.ceiling || {})) {
                        upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                        return true;
                    }
                    //Should only return when in upgradeLibrary because of isEmpty
                    if (
                        type !== "skylights" &&
                        component === "Window" &&
                        !isSkylight &&
                        upgradeType === compType &&
                        isEmpty(components)
                    ) {
                        upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                        return true;
                    } else if (
                        type !== "skylights" &&
                        component === "Window" &&
                        isSkylight &&
                        upgradeType === compType &&
                        isEmpty(components)
                    ) {
                        return false;
                    }

                    //Regular return for when in model
                    if (upgradeType === compType && selectedCompMatch) {
                        upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                        return true;
                    }

                    return false;
                })[0] || {};
        }

        if (!isEmpty(upgradeMatch)) {
            const field = fieldSelector(upgradeMatch.fields, codeCompMap[component].fieldPath) || {};

            if (field.codeRef === "UserSpecified") {
                const rval = fieldSelector(upgradeMatch.fields, `${codeCompMap[component].fieldPath}_nomRVal`) || 0;

                const composite = ["BasementWall", "CrawlspaceWall"].includes(component)
                    ? fieldSelector(upgradeMatch.fields, `${codeCompMap[component].fieldPath}_composite`) || {}
                    : {};

                if (!isEmpty(composite)) {
                    let compositeLabel = [0, 1, 2, 3].reduce((label, curr) => {
                        const { [`section${curr}`]: { percentage = 0, rsi = 0 } = {} } = composite || {};

                        if (percentage === 0) {
                            return label;
                        }

                        return `${label}R${(rsi * 5.678).toFixed(0)} (${percentage}%), `;
                    }, "");
                    compositeLabel = compositeLabel.slice(0, -2);
                    return {
                        label: compositeLabel,
                        upgradeIds: upgradeKeys,
                        type: component,
                    };
                }

                return {
                    label: `R${(rval * 5.678).toFixed(0)}`,
                    upgradeIds: upgradeKeys,
                    type: component,
                };
            } else if (component === "Door") {
                const { value: rval = 0, id: selectionId } = field;

                const doorOptions = getOptions({
                    fieldKey: codeCompMap[component].fieldKey,
                });

                let doorLabel = "";
                doorOptions.forEach((opt) => {
                    const { label, value: { id: optId = "" } = {} } = opt;
                    if (optId === parseInt(selectionId)) {
                        doorLabel = label;
                    }
                });

                return {
                    label: doorLabel + ` (R${(rval * 5.678).toFixed(1)})` || `R${(rval * 5.678).toFixed(1)}`,
                    upgradeIds: upgradeKeys,
                    type: component,
                };
            } else if (Object.keys(field).includes("id")) {
                const { value: rval = 0 } = field;
                return {
                    label: `R${(rval * 5.678).toFixed(0)}`,
                    upgradeIds: upgradeKeys,
                    type: component,
                };
            }

            let componentLabel = field.codeLabel;

            if (component === "Window") {
                const {
                    fields: {
                        windowType: { codeRef: windowCodeRef } = {},
                        windowType_effRVal: windowRVal = 0,
                        shgc = 0,
                    } = {},
                } = upgradeMatch;

                let windowLabel = getCodeLabel({ label: componentLabel, component });
                if (!windowCodeRef.includes("Window-U-")) {
                    windowLabel = windowLabel + ` (U-val = ${(1 / windowRVal).toFixed(2)}, SHGC = ${shgc.toFixed(2)})`;
                }

                return {
                    label: windowLabel,
                    upgradeIds: upgradeKeys,
                    type: component,
                };
            }

            return {
                label: getCodeLabel({ label: componentLabel, component }),
                upgradeIds: upgradeKeys,
                type: component,
            };
        }
    }

    // ================================== Check base file ======================================//
    //Getting largest component by size
    let compositeCodeDetails = {};
    if (component === "Ceiling" || component === "CeilingFlat") {
        //We must filter accessors for flat and attic
        codeDetails = accessors.reduce((cache, curr) => {
            const ceilingComp = fieldSelector(components, curr);
            const { constructionType: { id: constTypeId = 0 } = {} } = ceilingComp;
            const isFlat = [2, 3].includes(constTypeId);
            if (isFlat && component === "CeilingFlat") {
                const {
                    [codeCompMap[component].fieldPath]: { codeRef = "", codeLabel = "" } = {},
                    measurements: { area } = {},
                } = ceilingComp;

                if (codeRef === "UserSpecified") {
                    const rval = fieldSelector(ceilingComp, `${codeCompMap[component].fieldPath}_nomRVal`);
                    return {
                        ...cache,
                        [`${codeRef}-${rval}`]: area + (cache[codeRef] || 0),
                    };
                } else {
                    return {
                        ...cache,
                        [codeRef]: area + (cache[codeRef] || 0),
                    };
                }
            } else if (!isFlat && component === "Ceiling") {
                const {
                    [codeCompMap[component].fieldPath]: { codeRef = "", codeLabel = "" } = {},
                    measurements: { area } = {},
                } = ceilingComp;

                if (codeRef === "UserSpecified") {
                    const rval = fieldSelector(ceilingComp, `${codeCompMap[component].fieldPath}_nomRVal`);
                    return {
                        ...cache,
                        [`${codeRef}-${rval}`]: area + (cache[codeRef] || 0),
                    };
                } else {
                    return {
                        ...cache,
                        [codeRef]: area + (cache[codeRef] || 0),
                    };
                }
            } else {
                return cache;
            }
        }, {});
    } else {
        codeDetails = accessors.reduce((cache, curr) => {
            const compData = fieldSelector(components, curr);

            const field = fieldSelector(compData, codeCompMap[component].fieldPath) || {};

            const { codeRef = "", codeLabel = "" } = field;
            let area = 0;
            if (["BasementWall", "FloorsAdded", "Slab", "CrawlspaceWall"].includes(component)) {
                const {
                    isRectangular = false,
                    width,
                    length,
                    area: foundationArea,
                } = fieldSelector(compData, codeCompMap[component].areaPath) || {};

                area = isRectangular ? width * length : foundationArea;
            } else {
                area = fieldSelector(compData, codeCompMap[component].areaPath) || 0;
            }

            if (codeRef === "UserSpecified") {
                const rval = fieldSelector(compData, `${codeCompMap[component].fieldPath}_nomRVal`);
                const composite = ["BasementWall", "CrawlspaceWall"].includes(component)
                    ? fieldSelector(compData, `${codeCompMap[component].fieldPath}_composite`) || {}
                    : {};

                compositeCodeDetails[`${codeRef}-${rval}`] = composite;

                return {
                    ...cache,
                    [`${codeRef}-${rval}`]: area + (cache[codeRef] || 0),
                };
            } else if (Object.keys(field).includes("id")) {
                const { value: rval = 0, id } = field;

                return {
                    ...cache,
                    [`dropdown${id}-${rval}`]: area + (cache[codeRef] || 0),
                };
            }

            if (component === "Window") {
                windowDetails[codeRef] = {
                    rValue: fieldSelector(compData, `${codeCompMap[component].fieldPath}_effRVal`),
                    shgc: fieldSelector(compData, `${codeCompMap[component].shgcPath}`),
                };
            }

            return {
                ...cache,
                [codeRef]: area + (cache[codeRef] || 0),
            };
        }, {});
    }

    //Get code with largest area
    const matchCodeRef = Object.keys(codeDetails).reduce(
        (max, codeRef) => {
            if (codeDetails[codeRef] >= Object.values(max)[0]) {
                return { [codeRef]: codeDetails[codeRef] };
            } else {
                return max;
            }
        },
        { None: 0 }
    );

    if (Object.keys(matchCodeRef)[0] === "None") {
        return { label: "None", upgradeIds: upgradeKeys, type: component };
    }
    let { [Object.keys(matchCodeRef)[0]]: matchCode = {} } = codes;

    const matchCompositeDetails = compositeCodeDetails[Object.keys(matchCodeRef)[0]] || {};

    if (Object.keys(matchCodeRef)[0].includes("UserSpecified")) {
        const rval = Object.keys(matchCodeRef)[0].split("-")[1];

        matchCode = {
            label: `R${(rval * 5.678).toFixed(0)}`,
        };
    } else if (component === "Door") {
        const rval = Object.keys(matchCodeRef)[0].split("-")[1];

        //We should check if there's a label available for the option, otherwise return just the Rvalue
        const selectionId = Object.keys(matchCodeRef)[0].split("-")[0].split("dropdown")[1];

        const doorOptions = getOptions({
            fieldKey: codeCompMap[component].fieldKey,
        });

        let doorLabel = "";
        doorOptions.forEach((opt) => {
            const { label, value: { id: optId = "" } = {} } = opt;
            if (optId === parseInt(selectionId)) {
                doorLabel = label;
            }
        });

        return {
            label: doorLabel + ` (R${(rval * 5.678).toFixed(1)})` || `R${(rval * 5.678).toFixed(1)}`,
            upgradeIds: upgradeKeys,
            type: component,
        };
    } else if (Object.keys(matchCodeRef).some((key) => key.includes("dropdown"))) {
        const rval = Object.keys(matchCodeRef)[0].split("-")[1];
        return {
            label: `R${(rval * 5.678).toFixed(0)}`,
            upgradeIds: upgradeKeys,
            type: component,
        };
    }

    //Here we overwrite in cases with a composite code match
    //matchCompositeDetails can only be non-empty with BasementWall or CrawlspaceWall because of condition on creation
    if (Object.keys(matchCodeRef)[0].includes("UserSpecified") && !isEmpty(matchCompositeDetails)) {
        let compositeLabel = [0, 1, 2, 3].reduce((label, curr) => {
            const { [`section${curr}`]: { percentage = 0, rsi = 0 } = {} } = matchCompositeDetails || {};

            if (percentage === 0) {
                return label;
            }

            return `${label}R${(rsi * 5.678).toFixed(0)} (${percentage}%), `;
        }, "");
        compositeLabel = compositeLabel.slice(0, -2);
        matchCode = {
            label: compositeLabel,
        };
    }

    if (isEmpty(matchCode)) {
        return { label: "None", upgradeIds: upgradeKeys, type: component };
    }

    let componentLabel = matchCode.label;
    //Add u-value to window
    if (component === "Window") {
        const { [matchCode.codeRef]: { rValue: windowRVal, shgc } = {} } = windowDetails;

        let windowLabel = getCodeLabel({ label: componentLabel, component });
        if (!matchCode?.codeRef?.includes("Window-U-")) {
            windowLabel = windowLabel + ` (U-val=${(1 / windowRVal).toFixed(2)}, SHGC=${shgc.toFixed(2)})`;
        }

        return {
            label: windowLabel,
            upgradeIds: upgradeKeys,
            type: component,
        };
    }

    return {
        label: getCodeLabel({ label: componentLabel, component }),
        upgradeIds: upgradeKeys,
        type: component,
    };
};

const getAllComponents = (components, compType) => {
    //Search components for all accessors/paths to codeRefs of a given type (e.g. Wall, Ceiling, BasementWall, etc.)
    return components
        ? Object.keys(components[compType] || {}).reduce((cache, compKey) => {
              return [...cache, `${compType}.${compKey}`];
          }, [])
        : [];
};

const getAllSubcomponents = (components, compType) => {
    const { field, parents } = compatibleSubcomps[compType];

    let accessorsToUpdate = [];
    parents.forEach((parentName) => {
        const { [parentName]: parentComp = {} } = components;
        if (!isEmpty(parentComp)) {
            Object.entries(parentComp).forEach(([parentKey, parentObj]) => {
                const { subcomponents: { [compType]: subComp = {} } = {} } = parentObj;

                if (!isEmpty(subComp)) {
                    Object.keys(subComp).forEach((subCompKey) => {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `${parentName}.${parentKey}.subcomponents.${compType}.${subCompKey}`,
                        ];
                    });
                }
            });
        }
    });

    return accessorsToUpdate;
};

const getCodeLabel = ({ label = "1200000000", component = "Wall" }) => {
    if (!Object.keys(codeFields).includes(component)) {
        return label;
    }

    const field = codeFields[component];

    const firstCharMatch = label.substring(0, 2).match(field.regex[0]);
    const lastCharMatch = label.charAt(label.length - 1).match(field.regex[1]);

    if (label.length === field.length && firstCharMatch && lastCharMatch) {
        if (component === "Window") {
            let glazingLabel = "None";
            const glazingTypeValue = label.substring(field.glazingType.ind, field.glazingType.ind + 1);
            const glazingTypeOpts = getOptionsWithSimpleLabel({
                fieldKey: field.glazingType.name,
            }); //TODO: check this!!
            //Get glazing type label
            glazingTypeOpts.forEach((opt) => {
                const { value: { value: codeValue = "" } = {} } = opt || {};
                if (codeValue === glazingTypeValue) {
                    glazingLabel = opt.simpleLabel || "None";
                }
            });

            return glazingLabel;
        } else {
            let framingInsLabel = "None";
            let contInsLabel = "None";

            const structureTypeValue = label.substring(field.structureType.ind, field.structureType.ind + 1);
            const insLayer1Value = label.substring(field.layer1.ind, field.layer1.ind + 1);
            const insLayer2Value = label.substring(field.layer2.ind, field.layer2.ind + 1);

            const insLayer1Opts = getOptionsWithSimpleLabel({
                fieldKey: field.layer1.name,
                indKey: structureTypeValue,
            });
            const insLayer2Opts = getOptionsWithSimpleLabel({
                fieldKey: field.layer2.name,
            }); //TODO: check this

            //Get ins layer 1 (framing insulation) label
            insLayer1Opts.forEach((opt) => {
                const { value: { value: codeValue = "" } = {} } = opt || {};
                if (codeValue === insLayer1Value) {
                    framingInsLabel = opt.simpleLabel || "None";
                }
            });

            //Get ins layer 2 (continuous insulation) label
            insLayer2Opts.forEach((opt) => {
                const { value: { value: codeValue = "" } = {} } = opt || {};
                if (codeValue === insLayer2Value) {
                    contInsLabel = opt.simpleLabel || "None";
                }
            });

            if (framingInsLabel !== "None" && contInsLabel !== "None") {
                //Both found
                return `${framingInsLabel} + ${contInsLabel}`;
            } else if (framingInsLabel !== "None" || contInsLabel !== "None") {
                //Only one found
                return framingInsLabel !== "None" ? framingInsLabel : contInsLabel;
            }

            return "None";
        }
    }

    return label;
};

const codeCompMap = {
    Ceiling: {
        comp: "ceiling",
        fieldPath: "ceilingInsType",
    },
    CeilingFlat: {
        comp: "ceiling",
        fieldPath: "ceilingInsType",
    },
    Wall: {
        comp: "wall",
        fieldPath: "wallInsType",
        areaPath: "measurements.area",
    },
    Floor: {
        comp: "expFloor",
        fieldPath: "expFloorInsType",
        areaPath: "measurements.area",
    },
    BasementWall: {
        comp: "basement",
        fieldPath: "wall.intAddedInsType",
        areaPath: "floor.measurements",
    },
    CrawlspaceWall: {
        comp: "crawlspace",
        fieldPath: "wall.crawlspaceWallInsType",
        areaPath: "floor.measurements",
    },
    FloorsAdded: {
        comp: "basement",
        fieldPath: "floor.slabInsType",
        areaPath: "floor.measurements",
    },
    Slab: {
        comp: "slab",
        fieldPath: "floor.slabInsType",
        areaPath: "floor.measurements",
    },
    Window: {
        comp: "window",
        fieldPath: "windowType",
        shgcPath: "shgc",
        areaPath: "measurements.area",
    },
    FloorHeader: {
        comp: "floorHeader",
        fieldPath: "floorHeaderInsType",
        areaPath: "measurements.area",
    },
    Door: {
        comp: "door",
        fieldPath: "doorType",
        areaPath: "measurements.area",
        fieldKey: "doorType",
    },
};

const compatibleSubcomps = {
    window: {
        field: "windowType",
        parents: ["wall", "ceiling", "basement", "crawlspace"],
    },
    floorHeader: {
        field: "floorHeaderInsType",
        parents: ["wall", "basement", "crawlspace"],
    },
    door: {
        field: "doorType",
        parents: ["wall", "basement", "crawlspace"],
    },
};

const codeFields = {
    Wall: {
        structureType: { ind: 1, name: "csWallStructureType" },
        layer1: { ind: 4, name: "csWallInsLayer1" },
        layer2: { ind: 5, name: "csWallInsLayer2" },
        regex: [/^1[2-8]/, /[0-2]/], //first character/slice regex and last character regex
        length: 10,
    },
    FloorHeader: {
        structureType: { ind: 1, name: "csFloorHeaderStructureType" },
        layer1: { ind: 4, name: "csFloorHeaderInsLayer1" },
        layer2: { ind: 5, name: "csFloorHeaderInsLayer2" },
        regex: [/^1[8]/, /[0]/], //first character/slice regex and last character regex
        length: 10,
    },
    Floor: {
        structureType: { ind: 1, name: "csExpFloorStructureType" },
        layer1: { ind: 4, name: "csExpFloorInsLayer1" },
        layer2: { ind: 5, name: "csExpFloorInsLayer2" },
        regex: [/^3[2-7]/, /[0-1]/], //first character/slice regex and last character regex
        length: 10,
    },
    Ceiling: {
        structureType: { ind: 1, name: "csCeilingStructureType" },
        layer1: { ind: 4, name: "csCeilingInsLayer1" },
        layer2: { ind: 5, name: "csCeilingInsLayer2" },
        regex: [/^2[2-7]/, /[0]/], //first character/slice regex and last character regex
        length: 10,
    },
    CeilingFlat: {
        structureType: { ind: 1, name: "csCeilingStructureType" },
        layer1: { ind: 4, name: "csCeilingFlatInsLayer1" },
        layer2: { ind: 5, name: "csCeilingInsLayer2" },
        regex: [/^2[2-7]/, /[0]/], //first character/slice regex and last character regex
        length: 10,
    },
    BasementWall: {
        structureType: { ind: 0, name: "csBasementWallFraming" },
        layer1: { ind: 3, name: "csBasementWallFramingIns" },
        layer2: { ind: 4, name: "csBasementWallExtraIns" },
        regex: [/^[0-8][0-3]/, /[0-9]/], //first character/slice regex and last character regex
        length: 6,
    },
    Window: {
        glazingType: { ind: 0, name: "csWindowGlazingType" },
        regex: [/^[1-8]/, /[0-6]/], //first character/slice regex and last character regex
        length: 6,
    },
};

const getAirtightnessLabel = ({ airtightness, upgradePackage, returnType = "base" }) => {
    const { airtightnessInputType, normalizedAirLeakageRate, blowerTest: { airChangeRate50Pa } = {} } = airtightness;
    let upgradeKeys = [];

    if (returnType === "upgrade") {
        const packageKey = Object.keys(upgradePackage)[0];
        const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

        const upgradeMatch =
            Object.values(upgrades).filter((el, ind) => {
                const { upgradeType = "" } = el;
                if (upgradeType === "airTightness") {
                    upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                    return true;
                }

                return false;
            })[0] || {};

        if (!isEmpty(upgradeMatch)) {
            const {
                fields: {
                    airtightnessInputType,
                    normalizedAirLeakageRate,
                    blowerTest: { airChangeRate50Pa } = {},
                } = {},
            } = upgradeMatch;

            const nlrLabelAddition =
                airtightnessInputType === "nlr" && normalizedAirLeakageRate
                    ? ` (NLR = ${normalizedAirLeakageRate.toFixed(2)} L/s/m²)`
                    : "";

            return {
                label: `${airChangeRate50Pa.toFixed(1)} ACH${nlrLabelAddition}`,
                upgradeIds: upgradeKeys,
            };
        }
    }

    const nlrLabelAddition =
        airtightnessInputType === "nlr" && normalizedAirLeakageRate
            ? ` (NLR = ${normalizedAirLeakageRate.toFixed(2)} L/s/m²)`
            : "";

    return !isEmpty(airtightness)
        ? {
              label: `${airChangeRate50Pa.toFixed(1)} ACH${nlrLabelAddition}`,
              upgradeIds: upgradeKeys,
          }
        : { label: "" };
};

const getVentilationLabel = ({ ventilation, upgradePackage, returnType = "base" }) => {
    const { wholeHouseSystems: { hrv: baseHrvObj = {} } = {} } = ventilation || {};

    let upgradeKeys = [];

    if (returnType === "upgrade") {
        //check upgradePackage for ventilation upgrades
        const packageKey = Object.keys(upgradePackage)[0];
        const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

        const upgradeMatch =
            Object.values(upgrades).filter((el, ind) => {
                const { upgradeType = "" } = el;
                if (upgradeType === "ventilation") {
                    upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                    return true;
                }

                return false;
            })[0] || {};

        if (!isEmpty(upgradeMatch)) {
            const { fields: { wholeHouseSystems: { hrv: upHrvObj = {} } = {} } = {} } = upgradeMatch;
            if (!isEmpty(upHrvObj)) {
                const { efficiency1 } = upHrvObj || {};

                return {
                    label: `${efficiency1.toFixed(1)}% SRE HRV`,
                    upgradeIds: upgradeKeys,
                };
            }
        }
    }

    //No ventilation upgrade or base
    if (!isEmpty(baseHrvObj)) {
        const { efficiency1 } = baseHrvObj || {};

        return {
            label: `${efficiency1.toFixed(1)}% SRE HRV`,
            upgradeIds: upgradeKeys,
        };
    }

    return {
        label: "None",
        upgradeIds: upgradeKeys,
    };
};

const getHeatingCoolingLabel = ({ heatingCooling, upgradePackage, rowId, returnType = "base" }) => {
    let { heating: heatingObj = {}, cooling: coolingObj = {}, supplSystems: supplHeatingObj = {} } = heatingCooling;
    let upgradeKeys = [];
    if (returnType === "upgrade") {
        const packageKey = Object.keys(upgradePackage)[0];
        const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

        const upgradeMatch =
            Object.values(upgrades).filter((el, ind) => {
                const { upgradeType = "" } = el;
                if (upgradeType === "heatingCooling") {
                    upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                    return true;
                }

                return false;
            })[0] || {};

        if (!isEmpty(upgradeMatch)) {
            const {
                fields: { heating: upHeating = {}, cooling: upCooling = {}, supplSystems: upSupplHeating = {} } = {},
            } = upgradeMatch;
            heatingObj = upHeating;
            coolingObj = upCooling;
            supplHeatingObj = upSupplHeating;
            // heatingObj = isEmpty(upHeating) ? heatingObj : upHeating;
            // coolingObj = isEmpty(upCooling) ? coolingObj : upCooling;
            // supplHeatingObj = isEmpty(upSupplHeating) ? supplHeatingObj : upSupplHeating;
            // if (
            //     (rowId === "spaceHeating" && isEmpty(upHeating)) ||
            //     (rowId === "spaceCooling" && isEmpty(upCooling)) ||
            //     (rowId === "secondaryHeating" && isEmpty(upSupplHeating))
            // ) {
            //     upgradeKeys = []
            // }
        }
    }

    const { system: heatingSys = {} } = heatingObj;
    const { system: coolingSys = {} } = coolingObj;

    const heatingSysType = Object.keys(heatingSys)[0] || "";
    const coolingSysType = Object.keys(coolingSys)[0] || "";

    const {
        [coolingSysType]: {
            energyStar: coolingEStar = false,
            specifications: {
                coolingEfficiency: { isCop: isCoolingCop = false, value: coolingEff = 0 } = {},
                heatingEfficiency: { isCop: isHeatingCop = false, value: heatPumpEff = 0 } = {},
            } = {},
            equipmentType: { id: hpId } = {},
        } = {},
    } = coolingSys;

    if (rowId === "spaceHeating") {
        let heatingLabel = "";
        //Get heating system efficiency
        const {
            [heatingSysType]: {
                energyStar: heatingEStar = false,
                specifications: { efficiency, isSteadyState } = {},
                thermalPerformanceFactor = 0,
            } = {},
        } = heatingSys;

        if (heatingSysType === "baseboards") {
            heatingLabel = "Baseboards";
        } else if (heatingSysType !== "p9combo") {
            heatingLabel = efficiency
                ? `${efficiency.toFixed(1)}% ${isSteadyState ? "" : "AFUE "}${heatingSysType}`
                : "None";
        } else {
            heatingLabel = `${thermalPerformanceFactor.toFixed(2)} TPF P.9-11 combo system`;
        }

        if (coolingSysType.includes("HeatPump")) {
            let heatPumpType = coolingSysType.includes("air") ? "air source heat pump" : "ground source heat pump";
            if (hpId === 2) {
                heatPumpType = "mini-split ductless " + heatPumpType;
            }
            // coolingLabel = `${coolingEff.toFixed(2)} ${isCoolingCop ? "COP" : "SEER"} ${heatPumpType}`;
            if (heatPumpEff !== 0) {
                heatingLabel = `${heatPumpEff.toFixed(2)} ${isHeatingCop ? "COP" : "HSPF"} ${heatPumpType},${
                    coolingEStar ? " ENERGY STAR® certified, " : " "
                }${heatingLabel.toLowerCase()} backup`;
            }
        }

        if (heatingEStar) {
            heatingLabel = heatingLabel + ", ENERGY STAR® certified";
        }

        return {
            label: heatingLabel || "None",
            upgradeIds: upgradeKeys,
        };
    } else if (rowId === "secondaryHeating") {
        const { systems = {} } = supplHeatingObj;
        const firstSystem = Object.values(systems)[0] || {};
        let supplHeatingLabel = "";

        if (!isEmpty(firstSystem)) {
            const { energySource: { id: energySourceId = 0 } = {}, equipmentType: { id: equipTypeId = 0 } = {} } =
                firstSystem;

            const { label: fuelSourceLabel = "" } =
                getOptions({ fieldKey: "supplHtgEnergySource" }).find((el) => el.value.id === energySourceId) || {};
            const { label: equipTypeLabel = "" } =
                getOptions({
                    fieldKey: "supplHtgEquipType",
                    indKey: energySourceId,
                }).find((el) => el.value.id === equipTypeId) || {};
            supplHeatingLabel = `${fuelSourceLabel} ${equipTypeLabel.toLowerCase()}`;
        }

        return {
            label: supplHeatingLabel || "None",
            upgradeIds: upgradeKeys,
        };
    } else if (rowId === "spaceCooling") {
        let coolingLabel = "";
        if (coolingSysType === "airConditioning") {
            coolingLabel = `${coolingEff.toFixed(2)} ${isCoolingCop ? "COP" : "SEER"} A/C`;
        } else if (coolingSysType.includes("HeatPump")) {
            if (coolingEff == null) {
                //Heat pump in heating only mode
                coolingLabel = "Heating-only Heat Pump";
            } else {
                let heatPumpType = coolingSysType.includes("air") ? "air source heat pump" : "ground source heat pump";
                if (hpId === 2) {
                    heatPumpType = "mini-split ductless " + heatPumpType;
                }
                coolingLabel = `${coolingEff.toFixed(2)} ${isCoolingCop ? "COP" : "SEER"} ${heatPumpType}`;
            }
        } else {
            coolingLabel = "None";
        }

        if (coolingEStar) {
            coolingLabel = coolingLabel + ", ENERGY STAR® certified";
        }

        return {
            label: coolingLabel,
            upgradeIds: upgradeKeys,
        };
    }

    return {
        label: "None",
        upgradeIds: upgradeKeys,
    };
};

const getDhwLabel = ({ domesticHotWater, heatingCooling, upgradePackage, rowId, returnType = "base" }) => {
    let upgradeKeys = [];
    let dhwSystem = domesticHotWater;
    let heatingCoolingSystem = heatingCooling;

    if (returnType === "upgrade") {
        //check upgradePackage for domesticHotWater upgrades
        const packageKey = Object.keys(upgradePackage)[0];
        const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

        const upgradeMatch =
            Object.values(upgrades).filter((el, ind) => {
                const { upgradeType = "" } = el;
                if (upgradeType === "domesticHotWater") {
                    upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                    return true;
                }

                return false;
            })[0] || {};

        const heatingUpgradeMatch =
            Object.values(upgrades).filter((el) => el.upgradeType === "heatingCooling")[0] || {};

        dhwSystem = isEmpty(upgradeMatch) ? dhwSystem : upgradeMatch.fields;
        heatingCoolingSystem = isEmpty(heatingUpgradeMatch) ? heatingCoolingSystem : heatingUpgradeMatch.fields;
    }

    const {
        primarySystem: {
            energySource: { id: energySourceId = 0 } = {},
            tankType: { id: tankTypeId = 0 } = {},
            isUniform = false,
            heatPumpCOP = 0,
            energyFactor: { value: efValue = 0 } = {},
            drainWaterHeatRecovery = {},
        } = {},
        secondarySystem: { energySource: { id: secEnergySourceId = 0 } = {} } = {},
    } = dhwSystem;

    const { heating: { system: heatingSys = {} } = {} } = heatingCoolingSystem;
    const heatingSysType = Object.keys(heatingSys)[0] || "";

    let dhwLabel = "";
    let dwhrLabel = "";

    const energySourceOpts = getOptions({ fieldKey: "dhwEnergySource" });
    let { label: energySourceLabel = "" } =
        energySourceOpts.find(({ value = {} }) => value.id === energySourceId) || {};
    if (energySourceLabel === "Electricity") {
        energySourceLabel = "electric";
    }

    const tankTypeOpts = getOptions({
        fieldKey: "dhwTankType",
        indKey: energySourceId,
    });

    let { label: tankTypeLabel = "" } = tankTypeOpts.find(({ value = {} }) => value.id === tankTypeId) || {};

    if (energySourceId === 8) {
        let { label: secEnergySourceLabel = "" } = energySourceOpts.find(
            ({ value = {} }) => value.id === secEnergySourceId
        );
        dhwLabel = `Solar hot water heater with ${secEnergySourceLabel.toLowerCase()} backup`;
    } else if (tankTypeLabel.toLowerCase().includes("heat pump")) {
        dhwLabel = `${(heatPumpCOP * 0.9).toFixed(1)} ${isUniform ? "UEF" : "EF"} heat pump water heater`;
    } else {
        dhwLabel = `${efValue.toFixed(2)} ${
            isUniform ? "UEF" : "EF"
        } ${energySourceLabel.toLowerCase()} ${tankTypeLabel.toLowerCase()}`;
    }

    if (heatingSysType.toLowerCase().includes("combo")) {
        const {
            [heatingSysType]: {
                tankAndPump: { energyFactor: { value: comboEF = 0 } = {} } = {},
                thermalPerformanceFactor = 0,
            } = {},
        } = heatingSys;

        if (heatingSysType === "combo") {
            dhwLabel = `${comboEF.toFixed(2)} EF combo space heating/hot water system`;
        } else if (heatingSysType === "p9Combo") {
            dhwLabel = `${thermalPerformanceFactor.toFixed(2)} TPF P.9-11 combo space heating/hot water system`;
        }
    }

    if (!isEmpty(drainWaterHeatRecovery)) {
        const { preheatShowerTank = false, efficiencyRating } = drainWaterHeatRecovery;
        dwhrLabel = `${efficiencyRating.toFixed(1)}% DWHR (plumbed to ${
            preheatShowerTank ? "WH and shower" : "WH only"
        })`;
    }

    if (rowId === "dwhr") {
        return {
            label: dwhrLabel || "None",
            upgradeIds: upgradeKeys,
        };
    }

    return {
        label: dhwLabel || "None",
        upgradeIds: upgradeKeys,
    };
};

const getSolarPvLabel = ({ generation = {}, upgradePackage = {}, returnType = "base" }) => {
    let upgradeKeys = [];
    let generationSystem = generation;

    if (returnType === "upgrade") {
        // check upgradePackage for generation upgrades
        const packageKey = Object.keys(upgradePackage)[0];
        const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

        const upgradeMatch =
            Object.values(upgrades).filter((el, ind) => {
                const { upgradeType = "" } = el;
                if (upgradeType === "generation") {
                    upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                    return true;
                }

                return false;
            })[0] || {};

        generationSystem = isEmpty(upgradeMatch) ? generationSystem : upgradeMatch.fields;
    }

    const {
        hasBatteryStorage = false,
        pvCapacity = 0,
        solarReady = false,
        windEnergyContribution = 0,
    } = generationSystem;
    let generationLabel = "";

    if (pvCapacity > 0) {
        generationLabel = `${pvCapacity} kW Solar PV`;
    } else if (solarReady) {
        generationLabel = "Solar ready";
    }

    if (hasBatteryStorage && generationLabel !== "") {
        generationLabel = `${generationLabel}, battery storage`;
    } else if (hasBatteryStorage) {
        generationLabel = "Battery Storage";
    }

    if (windEnergyContribution > 0 && generationLabel !== "") {
        generationLabel = `${generationLabel}, ${windEnergyContribution} kWh annual wind energy`;
    } else if (windEnergyContribution > 0) {
        generationLabel = `${windEnergyContribution} kWh annual wind energy`;
    }

    return {
        label: generationLabel || "None",
        upgradeIds: upgradeKeys,
    };
};

const getBaseLoadsLabel = ({ baseLoads = {}, upgradePackage = {}, returnType = "base", rowId }) => {
    let upgradeKeys = [];
    let baseLoadsObj = baseLoads;

    if (returnType === "upgrade") {
        // check upgradePackage for generation upgrades
        const packageKey = Object.keys(upgradePackage)[0];
        const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

        const upgradeMatch =
            Object.values(upgrades).filter((el, ind) => {
                const { upgradeType = "" } = el;
                if (upgradeType === "baseLoads") {
                    upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                    return true;
                }

                return false;
            })[0] || {};

        baseLoadsObj = isEmpty(upgradeMatch) ? baseLoadsObj : upgradeMatch.fields;
    }

    const { waterUsage = {}, electricalUsage = {} } = baseLoadsObj || {};

    if (rowId === "bathroomFaucets") {
        const { bathroomFaucets: { id: faucetsId } = {} } = waterUsage;

        return {
            label: [0, 1].includes(faucetsId) ? "Low flow" : "None",
            upgradeIds: upgradeKeys,
        };
    } else if (rowId === "showerHeads") {
        const { shower: { flowRate: { id: showerId } = {} } = {} } = waterUsage;

        return {
            label: [0, 1].includes(showerId) ? "Low flow" : "None",
            upgradeIds: upgradeKeys,
        };
    } else if (rowId === "clothesWasher") {
        //energy star if energy < 197 kWh/year
        const { clothesWasher: { ratedValues: { annualEnergyConsumption = 197 } = {} } = {} } = waterUsage;

        return {
            label: annualEnergyConsumption < 197 ? "ENERGY STAR® certified" : "None",
            upgradeIds: upgradeKeys,
        };
    } else if (rowId === "dishwasher") {
        //energy star if energy < 260 kWh/year
        const { dishWasher: { ratedValues: { annualEnergyConsumption = 260 } = {} } = {} } = waterUsage;

        return {
            label: annualEnergyConsumption < 260 ? "ENERGY STAR® certified" : "None",
            upgradeIds: upgradeKeys,
        };
    } else if (rowId === "lighting") {
        const {
            interiorLighting: { dailyConsumption: { value: intLightingValue } = {} } = {},
            dryer: { ratedValue: { value: dryerRatedValue = 916 } = {} } = {},
            stove: { ratedValue: { value: stoveRatedValue = 565 } = {} } = {},
            refrigerator: { ratedValue: { value: fridgeRatedValue = 639 } = {} } = {},
        } = electricalUsage;
        let lightingLabel = "";

        if (intLightingValue === 1.6) {
            lightingLabel = "50% LEDs";
        } else if (intLightingValue < 1.6) {
            lightingLabel = "100% LEDs";
        }

        if (dryerRatedValue < 916 || stoveRatedValue < 565 || fridgeRatedValue < 639) {
            lightingLabel = `${lightingLabel}${lightingLabel === "" ? "" : ", "}ENERGY STAR® appliances`;
        }

        return {
            label: lightingLabel || "None",
            upgradeIds: upgradeKeys,
        };
    } else if (rowId === "monitoringSys") {
        const { hasEnergyMonitoringSystem = false } = electricalUsage;

        return {
            label: hasEnergyMonitoringSystem ? "Installed" : "None",
            upgradeIds: upgradeKeys,
        };
    }

    // dishwasher
    // lighting
    // monitoringSys

    // "0": {
    //     "english": "< 25% CFL or LED",
    //     "french": "< 25% LFC ou DEL",
    //     "value": 2.6
    // },
    // "1": {
    //     "english": "25%-75% CFL or LED",
    //     "french": "25 % à 75 % LFC ou DEL",
    //     "value": 1.6
    // },
    // "2": {
    //     "english": ">75% CFL or LED",
    //     "french": "> 75 % LFC ou DEL",
    //     "value": 0.6
    // }
    // {
    //     label:"User Specified",
    //     value:{
    //         id:3,
    //         value: intLightingValue
    //     }
    // }

    return {
        label: "None",
        upgradeIds: upgradeKeys,
    };
};

const fieldSelector = (obj, address) => {
    //grabs a value from obj based on address of keys
    //address in string separated by '.'
    return address.split(".").reduce((cache, key) => {
        return cache[key] == null ? cache : cache[key];
    }, obj);
};

const dollarFormatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const getFinancialValParams = (upgradePackage) => {
    let upgradeKeys = [];
    // check upgradePackage for generation upgrades
    const packageKey = Object.keys(upgradePackage)[0];
    const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

    const upgradeMatch =
        Object.values(upgrades).filter((el, ind) => {
            const { upgradeType = "" } = el;
            if (upgradeType === "financialValuation") {
                upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                return true;
            }

            return false;
        })[0] || {};

    const financialValuationObj = isEmpty(upgradeMatch) ? {} : upgradeMatch.fields;

    let { financialValuation: { discountRate, projectDuration } = {} } = financialValuationObj || {};

    if (isNaN(discountRate) || discountRate == null) {
        discountRate = 10;
    }

    //Coerce to between 0-100
    discountRate = Math.min(100, Math.max(0, discountRate));

    if (isNaN(projectDuration) || projectDuration == null) {
        projectDuration = 20;
    }

    projectDuration = Math.max(1, projectDuration);

    return { financialValuationObj: { financialValuation: { discountRate, projectDuration } }, upgradeKeys };
};

const getLoanObject = (upgradePackage) => {
    let upgradeKeys = [];
    // check upgradePackage for generation upgrades
    const packageKey = Object.keys(upgradePackage)[0];
    const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

    const upgradeMatch =
        Object.values(upgrades).filter((el, ind) => {
            const { upgradeType = "" } = el;
            if (upgradeType === "loan") {
                upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                return true;
            }

            return false;
        })[0] || {};

    const loanObject = isEmpty(upgradeMatch) ? {} : upgradeMatch.fields;

    return { loanObject, upgradeKeys };
};

const getGrantObject = (upgradePackage) => {
    let upgradeKeys = [];
    // check upgradePackage for generation upgrades
    const packageKey = Object.keys(upgradePackage)[0];
    const { [packageKey]: { upgrades = {} } = {} } = upgradePackage;

    const upgradeMatch =
        Object.values(upgrades).filter((el, ind) => {
            const { upgradeType = "" } = el;
            if (upgradeType === "grant") {
                upgradeKeys = [...upgradeKeys, Object.keys(upgrades)[ind]];
                return true;
            }

            return false;
        })[0] || {};

    const grantObject = isEmpty(upgradeMatch) ? {} : upgradeMatch.fields;

    return { grantObject, upgradeKeys };
};

const getLoanCost = (upgradePackage) => {
    const { loanObject = {} } = getLoanObject(upgradePackage);

    let loanDetails = [];

    const monthlyLoanCost = Object.values(loanObject).reduce((acc, loan) => {
        // const loan = Object.values(loanObject)[0];
        const principal = loan?.amount || 0;
        const rate = (loan?.rate || 0) / 100;
        const admin = loan?.adminFee || 0;
        const duration = Math.max(1, loan?.term || 0);
        const durationMonths = duration * 12;

        let monthlyAdminFee = 0;
        if (admin > 0) {
            monthlyAdminFee = (principal * (admin / 100)) / durationMonths;
        }

        let monthlyLoanPayment = principal / durationMonths;
        if (rate > 0) {
            const r = rate / 12;

            monthlyLoanPayment = (r * principal) / (1 - 1 / Math.pow(1 + r, durationMonths));
        }

        loanDetails = [
            ...loanDetails,
            { duration: Math.ceil(duration), annualPayment: 12 * (monthlyLoanPayment + monthlyAdminFee) },
        ];

        return acc + monthlyLoanPayment + monthlyAdminFee;
    }, 0);

    const annLoanTotalCost = monthlyLoanCost * 12;

    return { monthlyLoanCost, annLoanTotalCost, loanDetails };
};

const calcNPV = (rate, initialCost, cashFlowArr) =>
    cashFlowArr.reduce((acc, curr, ind) => acc + curr / Math.pow(rate / 100 + 1, ind + 1), initialCost);

const arrayElementAddition = (arrays) => {
    // Find the length of the longest array
    const maxLength = arrays.reduce((max, arr) => Math.max(max, arr.length), 0);

    // Create an array to store the result, initialized to 0s
    const result = new Array(maxLength).fill(0);

    // Iterate through each array
    arrays.forEach((arr) => {
        // Add each element to the corresponding element in the result array
        arr.forEach((num, index) => {
            result[index] += num;
        });
    });

    return result;
};

const getNetPresentValue = (upgradePackage, resultsData, resultsKey, baseResultsData, baseResultsKey, fuelPrices) => {
    const { financialValuationObj = {} } = getFinancialValParams({ [upgradePackage.id]: upgradePackage });
    const { financialValuation: { discountRate, projectDuration } = {} } = financialValuationObj || {};

    //Initial costs
    const totalCapitalCost = getTotalCapitalCost({ [upgradePackage.id]: upgradePackage }, true);

    const { loanObject = {} } = getLoanObject({ [upgradePackage.id]: upgradePackage }) || {};
    const { grantObject = {} } = getGrantObject({ [upgradePackage.id]: upgradePackage }) || {};

    const loansTotal = Object.values(loanObject).reduce((acc, loan) => {
        return acc + loan.amount;
    }, 0);

    const grantTotal = Object.values(grantObject).reduce((acc, grant) => {
        return acc + grant.amount;
    }, 0);

    const totalInitialCost = totalCapitalCost - Math.max(0, grantTotal + loansTotal);

    //cashflows
    const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "total");
    const baseOperatingExpenses = calcOperatingCosts(baseResultsData[baseResultsKey], fuelPrices, "total");

    const operatingCostSavings = baseOperatingExpenses - operatingExpenses; //savings will be positive value, positive cash flow

    const opCostCashFlow = new Array(projectDuration).fill(operatingCostSavings);

    const { loanDetails = [] } = getLoanCost({ [upgradePackage.id]: upgradePackage });

    const cashFlowArray = arrayElementAddition([
        opCostCashFlow,
        ...loanDetails.map((loan) => new Array(loan?.duration || 0).fill(-1 * loan?.annualPayment || 0)),
    ]);

    const npv = calcNPV(discountRate, -1 * Math.abs(totalInitialCost), cashFlowArray);

    return npv;
};

const internalRateOfReturn = () => {
    // annual rate of return on total
    // [(FV / PV)^(1/t) ]- 1
};

export const extractResultValues = ({
    allPackageResults = {},
    targetPackageId = "base",
    rowKey,
    fuelPrices = {},
    upgradePackage = {},
    packageUiSpecs = {},
    isBaseColumn = false,
}) => {
    // Here we get the values from the package - could break this into other functions
    const packageResults = allPackageResults?.[targetPackageId] || {};

    const { resultsData = {}, modelData = {} } = packageResults;
    const { location: { weather: { region: { id: regionId = 1 } = {} } = {} } = {} } = modelData;
    const provCode = provinceCodeMap[regionId];

    const defaultOpCond = Object.keys(resultsData).some((key) => key.includes("soc")) ? "soc" : "general";

    let { [targetPackageId]: { ui: { relativeBaseCase = "base", operatingConditions = defaultOpCond } = {} } = {} } =
        packageUiSpecs || {};

    //This handles the case when we delete an upgrade column and cannot find results
    relativeBaseCase = Object.keys(allPackageResults).includes(relativeBaseCase) ? relativeBaseCase : "base";

    let { [relativeBaseCase]: { ui: { operatingConditions: baseResultsOpCond = defaultOpCond } = {} } = {} } =
        packageUiSpecs || {};

    //We have to override here to handle switching from ERS to general mode
    operatingConditions = defaultOpCond === "general" ? defaultOpCond : operatingConditions;
    baseResultsOpCond = defaultOpCond === "general" ? defaultOpCond : baseResultsOpCond;

    //Select correct results set, whether or not in General/ERS/ORH mode
    //isBaseColumn; //Tells us if we're in the left-most column (never look for upgrades in results)

    const opCondResultsKey = Object.keys(resultsData).find((key) => {
        if (isBaseColumn) {
            return key.includes("BaseCase") && key.includes(operatingConditions);
        } else {
            return key.includes("AllUpgrades") && key.includes(operatingConditions);
        }
    });

    const resultsKey = opCondResultsKey || "";

    if (!resultsKey) {
        return "-";
    }

    const baseResults = allPackageResults?.[relativeBaseCase] || {};
    //baseResultsKey must consider the operating conditions selected in that particular case

    //Tells us if the selected base case references its own column (show "-" for %s)
    const selfRelativeBaseCase = targetPackageId === relativeBaseCase;
    const baseResultsKey = `${baseResultsOpCond}${relativeBaseCase === "base" ? "BaseCase" : "AllUpgrades"}`;

    if (rowKey === "totalEnergyConsumption") {
        const { zeroFloorTotal = 0, net = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);

        if (net < 0) {
            return `${formatNumDisplay(zeroFloorTotal, 2).replace("-0.00", "0.00")} (Net: ${formatNumDisplay(
                net,
                2
            ).replace("-0.00", "0.00")})`;
        }

        return formatNumDisplay(zeroFloorTotal, 2).replace("-0.00", "0.00");
    } else if (rowKey === "energyConsumptionReduction") {
        if (selfRelativeBaseCase) {
            return "-";
        }
        const { resultsData: baseResultsData = {} } = baseResults;

        const { zeroFloorTotal: netTotalConsumption = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);
        const { zeroFloorTotal: netBaseConsumption = 0 } = getNetTotalEnergyConsumption(
            baseResultsData[baseResultsKey]
        );

        const percentReduction = (100 * (netBaseConsumption - netTotalConsumption)) / netBaseConsumption;

        return `${percentReduction.toFixed(2)}%`;
    } else if (rowKey === "ghgEmissions") {
        const { resultsSummary: { annualEmissions = 0 } = {} } = resultsData[resultsKey];

        return formatNumDisplay(annualEmissions, 2);
    } else if (rowKey === "ghgEmissionReduction") {
        if (selfRelativeBaseCase) {
            return "-";
        }
        const { resultsData: baseResultsData = {} } = baseResults;

        const { resultsSummary: { annualEmissions = 0 } = {} } = resultsData[resultsKey];
        const { resultsSummary: { annualEmissions: baseAnnualEmissions = 0 } = {} } = baseResultsData[baseResultsKey];

        const percentReduction = (100 * (baseAnnualEmissions - annualEmissions)) / baseAnnualEmissions;

        return `${percentReduction.toFixed(2)}%`;
    } else if (rowKey === "nbc2020Tier") {
        if (!Object.keys(resultsData).includes("ersReference")) {
            return "-";
        }

        const { dimensions: { volume: { total: totalVolume = 0 } = {} } = {} } = modelData;

        const { calculatedTier, coolingValidationPassed } = getNbc2020Tier(
            resultsData[resultsKey],
            resultsData.ersReference,
            totalVolume
        );

        return coolingValidationPassed ? calculatedTier : "0";
    } else if (rowKey === "nbc2020OverallImprovement") {
        if (!Object.keys(resultsData).includes("ersReference")) {
            return "-";
        }

        const { annualEnergyConsumption: proposedAec = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);
        const { annualEnergyConsumption: refAec = 0 } = getNetTotalEnergyConsumption(resultsData.ersReference);

        const percentReduction = (100 * (refAec - proposedAec)) / refAec;

        return `${percentReduction.toFixed(2)}%`;
    } else if (rowKey === "nbc2020HeatLossReduction") {
        if (!Object.keys(resultsData).includes("ersReference")) {
            return "-";
        }

        const {
            [resultsKey]: {
                Annual: { HeatLoss: { _attributes: { total: proposedGrossHeatLoss } = {} } = {} } = {},
            } = {},
            ersReference: {
                Annual: { HeatLoss: { _attributes: { total: referenceGrossHeatLoss } = {} } = {} } = {},
            } = {},
        } = resultsData;

        const percentReduction = (100 * (referenceGrossHeatLoss - proposedGrossHeatLoss)) / referenceGrossHeatLoss;

        return `${percentReduction.toFixed(2)}%`;
    } else if (rowKey === "nbc2020PeakCoolingValidation") {
        if (!Object.keys(resultsData).includes("ersReference")) {
            return "-";
        }

        const {
            [resultsKey]: { Other: { _attributes: { designCoolLossRate: propDesignCoolLossRate = 0 } = {} } = {} } = {},
            ersReference: { Other: { _attributes: { designCoolLossRate: refDesignCoolLossRate = 0 } = {} } = {} } = {},
        } = resultsData;

        return `${propDesignCoolLossRate <= refDesignCoolLossRate ? "Pass" : "Fail"} (${propDesignCoolLossRate.toFixed(
            0
        )} / ${refDesignCoolLossRate.toFixed(0)})`;
    } else if (rowKey === "pcf2004PropEmissions") {
        const { [resultsKey]: proposedResults = {} } = resultsData;

        const propEmissions = getPcf2004ProposedEmissions(proposedResults, provCode) / 1000000; // [gCO2/y] ->> [tCO2/y]

        return formatNumDisplay(propEmissions, 2);
    } else if (rowKey === "pcf2004PerformanceLevel") {
        if (!Object.keys(resultsData).includes("ersReference")) {
            return "-";
        }

        const { [resultsKey]: proposedResults = {}, ersReference: ersReferenceResults = {} } = resultsData;

        const propEmissions = getPcf2004ProposedEmissions(proposedResults, provCode); // [gCO2/y]
        const refEmissions = getPcf2004RefEmissions(ersReferenceResults, provCode); // [gCO2/y]

        const percentSavings = (100 * (refEmissions - propEmissions)) / refEmissions;

        if (propEmissions > refEmissions) {
            return `Prop. exceeds Ref. (${(refEmissions / 1000000).toFixed(2)} t/y)`;
        }

        if (percentSavings < 10) {
            return `F (${percentSavings.toFixed(2)}%)`;
        } else if (percentSavings < 25) {
            return `E (${percentSavings.toFixed(2)}%)`;
        } else if (percentSavings < 50) {
            return `D (${percentSavings.toFixed(2)}%)`;
        } else if (percentSavings < 75) {
            return `C (${percentSavings.toFixed(2)}%)`;
        } else if (percentSavings < 90) {
            return `B (${percentSavings.toFixed(2)}%)`;
        } else if (percentSavings >= 90) {
            return `A (${percentSavings.toFixed(2)}%)`;
        }

        return "-";
    } else if (rowKey === "pcf1869EnergyUseIntensity") {
        if (!Object.keys(resultsData).includes("ersReference")) {
            return "-";
        }

        return getEuiComplianceTier(modelData, resultsData[resultsKey]);
    } else if (rowKey === "alternateCompliancePassed") {
        if (!Object.keys(resultsData).includes("ersReference")) {
            return "-";
        }
        const { [resultsKey]: proposedResults = {}, ersReference: ersReferenceResults = {} } = resultsData;

        const {
            dimensions: {
                volume: { total: totalVolume = 0 } = {},
                belowGradeIntFloorArea: { total: bgFloorArea = 0 } = {},
                aboveGradeIntFloorArea: { total: agFloorArea = 0 } = {},
            } = {},
            location: { weather: { location: { value: hdd = 0 } = {} } = {} } = {},
            specifications: { buildingSubType: { id: houseTypeId } = {} } = {},
        } = modelData;

        const totalFloorArea = agFloorArea + bgFloorArea;
        const { passed, volumeIneligibility } = getChbaNzAlternateCompliance(
            proposedResults,
            ersReferenceResults,
            totalVolume,
            totalFloorArea,
            hdd,
            houseTypeId
        );

        return passed ? `Pass${volumeIneligibility ? " (volume ineligible)" : ""}` : "Fail";
    } else if (rowKey === "designHeatLoss20") {
        const { Other: { _attributes: { designHeatLossRate = 0 } = {} } = {} } = resultsData[resultsKey];

        return formatNumDisplay(designHeatLossRate * 3.41, 0); //Btu/h
    } else if (rowKey === "designHeatGain31") {
        const { Other: { _attributes: { designCoolLossRate = 0 } = {} } = {} } = resultsData[resultsKey];

        return formatNumDisplay(designCoolLossRate * 3.41, 0); //Btu/h
    } else if (rowKey === "solarPanelsRequired") {
        //Solar panels @ 350W required to reach NZ

        const { zeroFloorTotal: netTotalConsumption = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);
        const { location: { weather: { location: { id: locationId = 1 } = {} } = {} } = {} } = modelData;
        const pvOutputPotential = pvPotential[locationId]; // [kWh/kWp]

        const panelCount = netTotalConsumption * (1 / 0.0036) * (1 / 0.35) * (1 / pvOutputPotential);

        return `${panelCount < 0.5 ? "Net Zero!" : Math.ceil(panelCount)}`;
    } else if (rowKey === "heatlossComfort") {
        const { Other: { _attributes: { designHeatLossRate = 0 } = {} } = {} } = resultsData[resultsKey];

        const {
            dimensions: {
                belowGradeIntFloorArea: { total: bgFloorArea = 0 } = {},
                aboveGradeIntFloorArea: { total: agFloorArea = 0 } = {},
            } = {},
        } = modelData;
        const heatlossComfort = (3.41 * designHeatLossRate) / ((bgFloorArea + agFloorArea) * 10.7693);

        return heatlossComfort.toFixed(0);
    } else if (rowKey === "conditioningDhwImprovement") {
        // (PreRetrofit EGHFconTotal GJ – PostRetrofit EGHFconTotal GJ) / (PreRetrofit EGHconTotal – 25.623 GJ SOC Baseload)
        if (selfRelativeBaseCase) {
            return "-";
        }

        const { resultsData: baseResultsData = {} } = baseResults;

        const {
            Annual: { Consumption: { Electrical: { _attributes: { baseload: baseloadGJ } = {} } = {} } = {} } = {},
        } = baseResultsData[baseResultsKey];

        const { zeroFloorTotal: netTotalConsumption = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);
        const { zeroFloorTotal: netBaseConsumption = 0 } = getNetTotalEnergyConsumption(
            baseResultsData[baseResultsKey]
        );

        const percentReduction = (100 * (netBaseConsumption - netTotalConsumption)) / (netBaseConsumption - baseloadGJ);

        return `${percentReduction.toFixed(2)}%`;
    } else if (rowKey === "grossHeatLossImprovement") {
        // Annual.HeatLoss._attribute.total % improvement compared to the reference house.
        if (!Object.keys(resultsData).includes("ersReference")) {
            return "-";
        }

        const {
            [resultsKey]: {
                Annual: { HeatLoss: { _attributes: { total: proposedGrossHeatLoss } = {} } = {} } = {},
            } = {},
            ersReference: {
                Annual: { HeatLoss: { _attributes: { total: referenceGrossHeatLoss } = {} } = {} } = {},
            } = {},
        } = resultsData;

        const percentReduction = (100 * (referenceGrossHeatLoss - proposedGrossHeatLoss)) / referenceGrossHeatLoss;

        return `${percentReduction.toFixed(2)}%`;
    } else if (rowKey === "grossHeatLoss") {
        const {
            [resultsKey]: {
                Annual: { HeatLoss: { _attributes: { total: proposedGrossHeatLoss } = {} } = {} } = {},
            } = {},
        } = resultsData;

        return `${proposedGrossHeatLoss.toFixed(2)}`;
    } else if (rowKey === "auxiliaryEnergy") {
        const { Annual: { Load: { _attributes: { auxiliaryEnergy = 0 } = {} } = {} } = {} } = resultsData[resultsKey];

        return (auxiliaryEnergy * (1 / 1000)).toFixed(2);
    } else if (rowKey === "energyUseIntensity") {
        const { annualEnergyConsumption = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);

        const {
            dimensions: {
                belowGradeIntFloorArea: { total: bgFloorArea = 0 } = {},
                aboveGradeIntFloorArea: { total: agFloorArea = 0 } = {},
            } = {},
        } = modelData;

        const totalFloorArea = agFloorArea + bgFloorArea;

        return (annualEnergyConsumption / totalFloorArea).toFixed(2);
    } else if (rowKey === "thermalEnergyDemandIntensity") {
        const { Annual: { Load: { _attributes: { auxiliaryEnergy = 0 } = {} } = {} } = {} } = resultsData[resultsKey];
        const {
            dimensions: {
                belowGradeIntFloorArea: { total: bgFloorArea = 0 } = {},
                aboveGradeIntFloorArea: { total: agFloorArea = 0 } = {},
            } = {},
        } = modelData;

        const totalFloorArea = agFloorArea + bgFloorArea;

        return ((auxiliaryEnergy * (1 / 3.6)) / totalFloorArea).toFixed(1);
    } else if (rowKey === "mechanicalEnergyUseIntensity") {
        const { annualEnergyConsumption = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);
        const { Annual: { Consumption: { Electrical: { _attributes: { baseload = 0 } = {} } = {} } = {} } = {} } =
            resultsData[resultsKey];
        const {
            dimensions: {
                belowGradeIntFloorArea: { total: bgFloorArea = 0 } = {},
                aboveGradeIntFloorArea: { total: agFloorArea = 0 } = {},
            } = {},
        } = modelData;

        const totalFloorArea = agFloorArea + bgFloorArea;

        const mechEnergyKWh = Math.max(0, annualEnergyConsumption - baseload) * (1 / 0.0036);

        return (mechEnergyKWh / totalFloorArea).toFixed(1);
    } else if (rowKey === "f280DesignHeatLoss") {
        const { f280Results: { heatLoss, heatingOverrideSetPoint } = {} } = resultsData[resultsKey];

        if (!heatLoss) {
            return "N/A";
        }

        if (heatingOverrideSetPoint != null) {
            return `${formatNumDisplay(heatLoss * 3.41, 0)} (at ${heatingOverrideSetPoint}˚C indoor set point)`; //Btu/h
        }

        return formatNumDisplay(heatLoss * 3.41, 0); //Btu/h
    } else if (rowKey === "f280DesignHeatGain") {
        const { f280Results: { latHeatGain, coolingOverrideSetPoint } = {} } = resultsData[resultsKey];
        if (!latHeatGain) {
            return "N/A";
        }

        if (coolingOverrideSetPoint != null) {
            return `${formatNumDisplay(latHeatGain * 3.41, 0)} (at ${coolingOverrideSetPoint}˚C indoor set point)`; //Btu/h
        }

        return formatNumDisplay(latHeatGain * 3.41, 0); //Btu/h
    } else if (rowKey === "naturalGasGj") {
        const {
            Annual: { Consumption: { NaturalGas: { _attributes: { total: totalNgGJ = 0 } = {} } = {} } = {} } = {},
        } = resultsData[resultsKey];

        return formatNumDisplay(totalNgGJ, 2);
    } else if (rowKey === "naturalGasM3") {
        const {
            Annual: { Consumption: { NaturalGas: { _attributes: { total: totalNgGJ = 0 } = {} } = {} } = {} } = {},
        } = resultsData[resultsKey];

        return formatNumDisplay(totalNgGJ / energyConversion.ngGJperm3, 2);
    } else if (rowKey === "electricityGj") {
        const {
            Annual: { Consumption: { Electrical: { _attributes: { total: totalElecGJ = 0 } = {} } = {} } = {} } = {},
        } = resultsData[resultsKey];

        const { grossElectricity = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);

        return formatNumDisplay(grossElectricity, 2);
    } else if (rowKey === "electricityKwh") {
        const {
            Annual: { Consumption: { Electrical: { _attributes: { total: totalElecGJ = 0 } = {} } = {} } = {} } = {},
        } = resultsData[resultsKey];

        const { grossElectricity = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);

        return formatNumDisplay(grossElectricity / energyConversion.elecGJperkWh, 2);
    } else if (rowKey === "propaneGj") {
        const {
            Annual: { Consumption: { Propane: { _attributes: { total: totalPropGJ = 0 } = {} } = {} } = {} } = {},
        } = resultsData[resultsKey];

        return formatNumDisplay(totalPropGJ, 2);
    } else if (rowKey === "propaneL") {
        const {
            Annual: { Consumption: { Propane: { _attributes: { total: totalPropGJ = 0 } = {} } = {} } = {} } = {},
        } = resultsData[resultsKey];

        return formatNumDisplay(totalPropGJ / energyConversion.propaneGJperL, 2);
    } else if (rowKey === "oilGj") {
        const { Annual: { Consumption: { Oil: { _attributes: { total: totalOilGJ = 0 } = {} } = {} } = {} } = {} } =
            resultsData[resultsKey];

        return formatNumDisplay(totalOilGJ, 2);
    } else if (rowKey === "oilL") {
        const { Annual: { Consumption: { Oil: { _attributes: { total: totalOilGJ = 0 } = {} } = {} } = {} } = {} } =
            resultsData[resultsKey];

        return formatNumDisplay(totalOilGJ / energyConversion.oilGJperL, 2);
    } else if (rowKey === "woodGj") {
        const { Annual: { Consumption: { Wood: { _attributes: { total: totalWoodGJ = 0 } = {} } = {} } = {} } = {} } =
            resultsData[resultsKey];

        return formatNumDisplay(totalWoodGJ, 2);
    } else if (rowKey === "woodCord") {
        const { Annual: { Consumption: { Wood: { _attributes: { total: totalWoodGJ = 0 } = {} } = {} } = {} } = {} } =
            resultsData[resultsKey];

        return formatNumDisplay(totalWoodGJ / energyConversion.woodGJperCord, 2);
    } else if (rowKey === "pvProductionGj") {
        const { pvProduction = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);

        return formatNumDisplay(pvProduction, 2);
    } else if (rowKey === "pvProductionKwh") {
        const { pvProduction = 0 } = getNetTotalEnergyConsumption(resultsData[resultsKey]);

        return formatNumDisplay(pvProduction / energyConversion.elecGJperkWh, 2);
    } else if (rowKey === "operatingExpenses") {
        const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "total");

        return dollarFormatter.format(operatingExpenses);
    } else if (rowKey === "operatingCostSavings") {
        if (selfRelativeBaseCase) {
            return "-";
        }

        const { resultsData: baseResultsData = {} } = baseResults;

        const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "total");
        const baseOperatingExpenses = calcOperatingCosts(baseResultsData[baseResultsKey], fuelPrices, "total");

        const percentReduction = (100 * (baseOperatingExpenses - operatingExpenses)) / baseOperatingExpenses;

        return `${percentReduction.toFixed(2)}%`;
    } else if (rowKey === "annualNaturalGasCost") {
        const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "naturalGas");

        return dollarFormatter.format(operatingExpenses);
    } else if (rowKey === "annualElectricityCost") {
        const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "electricity");

        return dollarFormatter.format(operatingExpenses);
    } else if (rowKey === "annualPropaneCost") {
        const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "propane");

        return dollarFormatter.format(operatingExpenses);
    } else if (rowKey === "annualOilCost") {
        const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "oil");

        return dollarFormatter.format(operatingExpenses);
    } else if (rowKey === "annualWoodCost") {
        const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "wood");

        return dollarFormatter.format(operatingExpenses);
    } else if (rowKey === "simplePayback") {
        if (selfRelativeBaseCase) {
            return "-";
        }

        const { resultsData: baseResultsData = {} } = baseResults;

        const operatingExpenses = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "total");
        const baseOperatingExpenses = calcOperatingCosts(baseResultsData[baseResultsKey], fuelPrices, "total");

        if (operatingExpenses >= baseOperatingExpenses) {
            return "No savings expected";
        }

        const annualSavings = baseOperatingExpenses - operatingExpenses;

        const totalCapitalCost = getTotalCapitalCost({ [upgradePackage.id]: upgradePackage }, true);

        if (totalCapitalCost <= 0) {
            return "No costs provided";
        }

        const simplePayback = totalCapitalCost / annualSavings;

        if (simplePayback > 100) {
            return "+100";
        }

        return simplePayback.toFixed(1);
    } else if (rowKey === "annLoanCost") {
        if (isEmpty(upgradePackage)) {
            return "-";
        }

        const { monthlyLoanCost, annLoanTotalCost } = getLoanCost({ [upgradePackage.id]: upgradePackage });
        return dollarFormatter.format(annLoanTotalCost);
    } else if (rowKey === "totalCostOwnership") {
        const operatingCost = calcOperatingCosts(resultsData[resultsKey], fuelPrices, "total");

        let { financialValuationObj = {} } = getFinancialValParams(upgradePackage);
        const { financialValuation: { projectDuration } = {} } = financialValuationObj || {};
        const lifetimeOpCosts = projectDuration * operatingCost;
        if (isEmpty(upgradePackage)) {
            //No loans in base case, always operating cost
            return `${dollarFormatter.format(lifetimeOpCosts)} (over 20 years)`;
        }

        const totalCapitalCost = getTotalCapitalCost({ [upgradePackage.id]: upgradePackage }, true);

        const { loanObject = {} } = getLoanObject({ [upgradePackage.id]: upgradePackage }) || {};
        const { grantObject = {} } = getGrantObject({ [upgradePackage.id]: upgradePackage }) || {};

        const loansTotal = Object.values(loanObject).reduce((acc, loan) => {
            return acc + loan.amount;
        }, 0);

        const grantTotal = Object.values(grantObject).reduce((acc, grant) => {
            return acc + grant.amount;
        }, 0);

        const totalInitialCost = totalCapitalCost - Math.max(0, grantTotal + loansTotal);

        const { loanDetails = [] } = getLoanCost({ [upgradePackage.id]: upgradePackage });
        const totalLoanCost = loanDetails.reduce(
            (acc, curr) => acc + (curr?.duration || 0) * (curr?.annualPayment || 0),
            0
        );

        return dollarFormatter.format(lifetimeOpCosts + totalLoanCost + totalInitialCost);
    } else if (rowKey === "netPresentValue") {
        if (!selfRelativeBaseCase || !isEmpty(upgradePackage)) {
            const { resultsData: baseResultsData = {} } = baseResults;
            return dollarFormatter.format(
                getNetPresentValue(upgradePackage, resultsData, resultsKey, baseResultsData, baseResultsKey, fuelPrices)
            );
        } else {
            return "-";
        }
    }
    return `${rowKey}, ${resultsKey}`;
};

export const getBarGraphResultsData = (packageResults = {}, id, resType, uiSpecs = {}) => {
    const { resultsData = {} } = packageResults;

    const defaultOpCond = Object.keys(resultsData).some((key) => key.includes("soc")) ? "soc" : "general";
    let { ui: { operatingConditions = defaultOpCond } = {} } = uiSpecs;

    operatingConditions = defaultOpCond === "general" ? defaultOpCond : operatingConditions;

    //Select correct results set, whether or not in General/ERS/ORH mode
    const isBaseColumn = resType === "base"; //Tells us if we're in left-most column

    const opCondResultsKey = Object.keys(resultsData).find((key) => {
        if (isBaseColumn) {
            return key.includes("BaseCase") && key.includes(operatingConditions);
        } else {
            return key.includes("AllUpgrades") && key.includes(operatingConditions);
        }
    });

    const resultsKey = opCondResultsKey || "";

    if (!resultsKey) {
        return { amount: 0, label: "0%" };
    }

    //Determine which set we're working with...
    const resultsSetName = simulationEnergyUseHeadings.map((el) => el.id).includes(id) ? "energyEndUse" : "heatLoss";

    if (resultsSetName === "energyEndUse") {
        const {
            Annual: {
                Consumption: {
                    SpaceHeating: {
                        _attributes: {
                            primary: primaryHeating = 0,
                            secondary: secondaryHeating = 0,
                            total: totalSpaceHeating = 0,
                        } = {},
                    } = {},
                    HotWater: { _attributes: { primary: primaryDHW = 0, secondary: secondaryDHW = 0 } = {} } = {},
                    Electrical: { _attributes: { baseload = 0, ventilation = 0, airConditioning = 0 } = {} } = {},
                } = {},
            } = {},
        } = resultsData[resultsKey];

        const resPackage = {
            primaryHeating: Math.max(0, totalSpaceHeating - secondaryHeating),
            secondaryHeating,
            primaryDHW,
            secondaryDHW,
            lightsAppliances: baseload,
            hrvFans: ventilation,
            airConditioner: airConditioning,
        };

        const total = Object.values(resPackage).reduce((sum, curr) => sum + curr, 0);
        const amount = resPackage[id].toFixed(1);
        const percent = (100 * amount) / total;

        return {
            amount,
            label: `${amount} GJ (${percent.toFixed(0)}%)`,
        };
    } else if (resultsSetName === "heatLoss") {
        const {
            Annual: {
                HeatLoss: {
                    _attributes: {
                        ceiling = 0,
                        mainWalls = 0,
                        doors = 0,
                        exposedFloors = 0,
                        windows = 0,
                        basementBelowGradeWall = 0,
                        basementFloorHeaders = 0,
                        slab = 0,
                        crawlspace = 0,
                        ponyWall = 0,
                        basementAboveGradeWall = 0,
                        airLeakageAndNaturalVentilation = 0,
                    } = {},
                } = {},
            } = {},
        } = resultsData[resultsKey];

        const resPackage = {
            ceilings: ceiling,
            mainWalls,
            doors,
            expFloors: exposedFloors,
            windows,
            foundation:
                basementBelowGradeWall + basementFloorHeaders + slab + crawlspace + ponyWall + basementAboveGradeWall,
            ventilation: airLeakageAndNaturalVentilation,
        };

        const total = Object.values(resPackage).reduce((sum, curr) => sum + curr, 0);
        const amount = resPackage[id].toFixed(1);
        const percent = (100 * amount) / total;

        return {
            amount,
            label: `${amount} GJ (${percent.toFixed(0)}%)`,
        };
    }

    return { amount: 0, label: "0%" };
};

export const getBarGraphEnvelopeBreakdownResultsData = (packageResults = {}, id, measure) => {
    const { resultsData = {} } = packageResults;

    //Always return BaseCase
    const baseResultsKey = Object.keys(resultsData).includes("socBaseCase") ? "socBaseCase" : "generalBaseCase";

    const { zeroFloorTotal: baseEnergyTotal = 0 } = getNetTotalEnergyConsumption(resultsData[baseResultsKey]);

    const baseEmissions = resultsData[baseResultsKey]?.resultsSummary?.annualEmissions || 0;

    if (id === "baseConsumption") {
        if (measure === "Consumption") {
            return {
                amount: baseEnergyTotal,
                label: formatNumDisplay(baseEnergyTotal, 2).replace("-0.00", "0.00"),
            };
        } else if (measure === "annualEmissions") {
            return {
                amount: baseEmissions,
                label: formatNumDisplay(baseEmissions, 2).replace("-0.00", "0.00"),
            };
        }
    } else if (id === "upgradeConsumption") {
        //Always return AllUpgrades
        const upgradeResultsKey = Object.keys(resultsData).includes("socAllUpgrades")
            ? "socAllUpgrades"
            : "generalAllUpgrades";
        if (measure === "Consumption") {
            const { zeroFloorTotal = 0 } = getNetTotalEnergyConsumption(resultsData[upgradeResultsKey]);

            const percentDiff = (100 * (zeroFloorTotal - baseEnergyTotal)) / baseEnergyTotal;

            return {
                amount: zeroFloorTotal,
                label: `${zeroFloorTotal.toFixed(2)} (${percentDiff > 0 ? "+" : ""}${percentDiff.toFixed(1)}%)`, //formatNumDisplay(zeroFloorTotal, 2).replace("-0.00", "0.00"),
            };
        } else if (measure === "annualEmissions") {
            const amount = resultsData[upgradeResultsKey]?.resultsSummary?.annualEmissions || 0;
            const percentDiff = (100 * (amount - baseEmissions)) / baseEmissions;
            return {
                amount,
                label: `${amount.toFixed(1)} (${percentDiff > 0 ? "+" : ""}${percentDiff.toFixed(1)}%)`, //formatNumDisplay(amount, 2).replace("-0.00", "0.00"),
            };
        }
    }

    if (!id || !Object.keys(resultsData).includes(id)) {
        return { amount: 0, label: "" };
    }

    const keysArray = Object.keys(resultsData).reduce((acc, key) => {
        if (key.includes(id)) {
            return [...acc, key];
        } else {
            return acc;
        }
    }, []);

    if (measure === "Consumption") {
        if (keysArray.length > 0) {
            const resPackage = keysArray.reduce((acc, key) => {
                const { Annual: { [`${measure}`]: { _attributes: { total: value = 0 } = {} } = {} } = {} } =
                    resultsData[key];
                return { ...acc, [`${key}`]: value };
            }, {});

            const amount = resPackage[id].toFixed(2);
            const percentDiff = (100 * (amount - baseEnergyTotal)) / baseEnergyTotal;

            return {
                amount,
                label: `${amount} (${percentDiff > 0 ? "+" : ""}${percentDiff.toFixed(1)}%)`,
            };
        }
    } else if (measure === "annualEmissions") {
        const emissionsResult = resultsData[id]?.resultsSummary?.annualEmissions;

        if (emissionsResult) {
            const amount = emissionsResult.toFixed(1);
            const percentDiff = (100 * (amount - baseEmissions)) / baseEmissions;

            return {
                amount,
                label: `${amount} (${percentDiff > 0 ? "+" : ""}${percentDiff.toFixed(1)}%)`, //formatNumDisplay(amount, 2).replace("-0.00", "0.00"),
            };
        } else {
            return { amount: 0, label: "" };
        }
    } else {
        return { amount: 0, label: "" };
    }
};

export const calcOperatingCosts = (resultsObj = {}, fuelPrices = {}, returnType = "total") => {
    const {
        Annual: {
            Consumption: {
                Electrical: { _attributes: { total: totalElecGJ = 0 } = {} } = {},
                NaturalGas: { _attributes: { total: totalNgGJ = 0 } = {} } = {},
                Propane: { _attributes: { total: totalPropaneGJ = 0 } = {} } = {},
                Oil: { _attributes: { total: totalOilGJ = 0 } = {} } = {},
                Wood: { _attributes: { total: totalWoodGJ = 0 } = {} } = {},
            } = {},
        } = {},
    } = resultsObj;

    //Check for price info, handle differently if none is present
    const elecDefault = 0.16;
    const ngDefault = 0.3;
    const propDefault = 1.28;
    const oilDefault = 2.31;
    const woodDefault = 460;
    let {
        electricity: {
            dollarsPerUnit: elecCostPerUnit = elecDefault,
            dollarsPerMonth: elecPerMonth = 0,
            preTaxRebate: elecRebate = 0,
            tax: elecTax = 0,
        } = {},
        naturalGas: {
            dollarsPerUnit: ngCostPerUnit = ngDefault,
            dollarsPerMonth: ngPerMonth = 0,
            preTaxRebate: ngRebate = 0,
            tax: ngTax = 0,
        } = {},
        propane: {
            dollarsPerUnit: propCostPerUnit = propDefault,
            dollarsPerMonth: propPerMonth = 0,
            preTaxRebate: propRebate = 0,
            tax: propTax = 0,
        } = {},
        oil: {
            dollarsPerUnit: oilCostPerUnit = oilDefault,
            dollarsPerMonth: oilPerMonth = 0,
            preTaxRebate: oilRebate = 0,
            tax: oilTax = 0,
        } = {},
        wood: {
            dollarsPerUnit: woodCostPerUnit = woodDefault,
            dollarsPerMonth: woodPerMonth = 0,
            preTaxRebate: woodRebate = 0,
            tax: woodTax = 0,
        } = {},
    } = fuelPrices;

    //NaNs don't get destructured as if they're undefined, so we have to impose the
    elecCostPerUnit = isNaN(elecCostPerUnit) ? elecDefault : elecCostPerUnit;
    ngCostPerUnit = isNaN(ngCostPerUnit) ? ngDefault : ngCostPerUnit;
    propCostPerUnit = isNaN(propCostPerUnit) ? propDefault : propCostPerUnit;
    oilCostPerUnit = isNaN(oilCostPerUnit) ? oilDefault : oilCostPerUnit;
    woodCostPerUnit = isNaN(woodCostPerUnit) ? woodDefault : woodCostPerUnit;

    const elecKwh = totalElecGJ / energyConversion.elecGJperkWh;
    const ngM3 = totalNgGJ / energyConversion.ngGJperm3;
    const propL = totalPropaneGJ / energyConversion.propaneGJperL;
    const oilL = totalOilGJ / energyConversion.oilGJperL;
    const woodCord = totalWoodGJ / energyConversion.woodGJperCord;

    const elecCost = calcIndividualFuelCost(elecKwh, elecCostPerUnit, elecPerMonth, elecRebate, elecTax);
    const ngCost = calcIndividualFuelCost(ngM3, ngCostPerUnit, ngPerMonth, ngRebate, ngTax);
    const propCost = calcIndividualFuelCost(propL, propCostPerUnit, propPerMonth, propRebate, propTax);
    const oilCost = calcIndividualFuelCost(oilL, oilCostPerUnit, oilPerMonth, oilRebate, oilTax);
    const woodCost = calcIndividualFuelCost(woodCord, woodCostPerUnit, woodPerMonth, woodRebate, woodTax);

    return {
        electricity: elecCost,
        naturalGas: ngCost,
        propane: propCost,
        oil: oilCost,
        wood: woodCost,
        total: elecCost + ngCost + propCost + oilCost + woodCost,
    }[returnType];
};

export const getOperatingExpensesBarGraphData = (packageResults = {}, id, fuelPrices, resType) => {
    const { resultsData = {}, modelData = {} } = packageResults;

    //Where id === "baseConsumption" if base case and id === "upgradeConsumption" if all upgrades case
    const baseResultsKey = Object.keys(resultsData).includes("socBaseCase") ? "socBaseCase" : "generalBaseCase";
    const baseAmount = calcOperatingCosts(resultsData[baseResultsKey], fuelPrices, "total");
    if (id === "baseConsumption") {
        return {
            amount: baseAmount,
            label: [`$${formatNumDisplay(baseAmount, 0)}`],
        };
    } else if (id === "upgradeConsumption") {
        const allUpgradesResultsKey = Object.keys(resultsData).includes("socAllUpgrades")
            ? "socAllUpgrades"
            : "generalAllUpgrades";
        const amount = calcOperatingCosts(resultsData[allUpgradesResultsKey], fuelPrices, "total");
        const percentDiff = (100 * (amount - baseAmount)) / baseAmount;

        return {
            amount,
            label: [`$${formatNumDisplay(amount, 0)} (${percentDiff > 0 ? "+" : ""}${percentDiff.toFixed(1)}%)`],
        };
    }

    //Below handles iterating through breakdown results (socCeilings, socWalls, etc.)

    const keysArray = Object.keys(resultsData).reduce((acc, key) => {
        if (key.includes(id)) {
            return [...acc, key];
        } else {
            return acc;
        }
    }, []);

    if (keysArray.length > 0) {
        const amount = calcOperatingCosts(resultsData[id], fuelPrices, "total");
        const percentDiff = (100 * (amount - baseAmount)) / baseAmount;
        return {
            amount,
            label: [`$${formatNumDisplay(amount, 0)} (${percentDiff > 0 ? "+" : ""}${percentDiff.toFixed(1)}%)`],
        };
    } else {
        return {
            amount: "",
            label: "",
        };
    }
};

const calcIndividualFuelCost = (amount, unitCost, monthCost, rebate, tax) => {
    if (amount === 0) {
        return 0;
    }

    unitCost = isNaN(unitCost) ? 0 : unitCost;
    monthCost = isNaN(monthCost) ? 0 : monthCost;
    rebate = isNaN(rebate) ? 0 : rebate;
    tax = isNaN(tax) ? 0 : tax;

    const fixedCost = amount > 0 ? monthCost * 12 : 0;
    const initCost = amount * unitCost + fixedCost;
    const rebateAmount = initCost * (rebate / 100);
    const taxAmount = initCost * (tax / 100);

    return initCost + taxAmount - rebateAmount || 0;
};

export const getInitComponentUpgradeFields = (components, accessor, componentType) => {
    const componentData = fieldSelector(components, accessor) || {};

    if (isEmpty(componentData)) {
        return {};
    }

    if (componentType === "ceiling") {
        return {
            fields: {
                constructionType: componentData.constructionType || {},
                ceilingInsType: componentData.ceilingInsType || {},
                ceilingInsType_effRVal: componentData.ceilingInsType_effRVal || 0,
                ceilingInsType_nomRVal: componentData.ceilingInsType_nomRVal || 0,
            },
        };
    } else if (componentType === "wall") {
        return {
            fields: {
                wallInsType: componentData.wallInsType || {},
                wallInsType_effRVal: componentData.wallInsType_effRVal || 0,
                wallInsType_nomRVal: componentData.wallInsType_nomRVal || 0,
                lintelInsType: componentData.lintelInsType || {},
            },
        };
    } else if (componentType === "expFloor") {
        return {
            fields: {
                expFloorInsType: componentData.expFloorInsType || {},
                expFloorInsType_effRVal: componentData.expFloorInsType_effRVal || 0,
                expFloorInsType_nomRVal: componentData.expFloorInsType_nomRVal || 0,
            },
        };
    } else if (componentType === "floorHeader") {
        return {
            fields: {
                floorHeaderInsType: componentData.floorHeaderInsType || {},
                floorHeaderInsType_effRVal: componentData.floorHeaderInsType_effRVal || 0,
                floorHeaderInsType_nomRVal: componentData.floorHeaderInsType_nomRVal || 0,
            },
        };
    } else if (componentType === "window") {
        return {
            fields: {
                windowType: componentData.windowType || {},
                windowType_effRVal: componentData.windowType_effRVal || 0,
                windowType_nomRVal: componentData.windowType_nomRVal || 0,
                shgc: componentData.shgc || 0,
                er: componentData.er || 0,
            },
        };
    } else if (componentType === "door") {
        return {
            fields: {
                doorType: componentData.doorType || {},
                energyStar: componentData.energyStar || false,
            },
        };
    } else if (componentType === "basement") {
        return {
            fields: {
                wall: {
                    extAddedInsType: componentData?.wall?.extAddedInsType || {},
                    extAddedInsType_composite: componentData?.wall?.extAddedInsType_composite || {},
                    intAddedInsType: componentData?.wall?.intAddedInsType || {},
                    intAddedInsType_nomRVal: componentData?.wall?.intAddedInsType_nomRVal || 0,
                    intAddedInsType_effRVal: componentData?.wall?.intAddedInsType_effRVal || 0,
                    intAddedInsType_composite: componentData?.wall?.intAddedInsType_composite || {},
                    lintelInsType: componentData?.wall?.lintelInsType || {},
                    ponyWall: componentData?.wall?.ponyWall || {},
                },
                floor: {
                    floorsAboveInsType: componentData?.floor?.floorsAboveInsType || {},
                    floorsAboveInsType_nomRVal: componentData?.floor?.floorsAboveInsType_nomRVal || 0,
                    floorsAboveInsType_effRVal: componentData?.floor?.floorsAboveInsType_effRVal || 0,
                    slabInsType: componentData?.floor?.slabInsType || {},
                    slabInsType_nomRVal: componentData?.floor?.slabInsType_nomRVal || 0,
                    slabInsType_effRVal: componentData?.floor?.slabInsType_effRVal || 0,
                },
            },
        };
    } else if (componentType === "crawlspace") {
        return {
            fields: {
                wall: {
                    crawlspaceWallInsType: componentData?.wall?.crawlspaceWallInsType || {},
                    crawlspaceWallInsType_nomRVal: componentData?.wall?.crawlspaceWallInsType_nomRVal || 0,
                    crawlspaceWallInsType_effRVal: componentData?.wall?.crawlspaceWallInsType_effRVal || 0,
                    crawlspaceWallInsType_composite: componentData?.wall?.crawlspaceWallInsType_composite || {},
                    lintelInsType: componentData?.wall?.lintelInsType || {},
                },
                floor: {
                    floorsAboveInsType: componentData?.floor?.floorsAboveInsType || {},
                    floorsAboveInsType_nomRVal: componentData?.floor?.floorsAboveInsType_nomRVal || 0,
                    floorsAboveInsType_effRVal: componentData?.floor?.floorsAboveInsType_effRVal || 0,
                    slabInsType: componentData?.floor?.slabInsType || {},
                    slabInsType_nomRVal: componentData?.floor?.slabInsType_nomRVal || 0,
                    slabInsType_effRVal: componentData?.floor?.slabInsType_effRVal || 0,
                },
            },
        };
    } else if (componentType === "slab") {
        return {
            fields: {
                floor: {
                    slabInsType: componentData?.floor?.slabInsType || {},
                    slabInsType_nomRVal: componentData?.floor?.slabInsType_nomRVal || 0,
                    slabInsType_effRVal: componentData?.floor?.slabInsType_effRVal || 0,
                },
            },
        };
    }
    return {};
};

// export const getInitComponentUpgradeFields = (components, availableComponents, componentType) => {
//     return availableComponents.reduce((cache, availComp) => {
//         const {value: accessor} = availComp;

//         const componentData = fieldSelector(components, accessor) || {};

//         if (isEmpty(componentData)) {
//             return cache
//         }

//         if (componentType === 'ceiling') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                     fields: {
//                         constructionType: componentData.constructionType,
//                         ceilingInsType: componentData.ceilingInsType,
//                         ceilingInsType_effRVal: componentData.ceilingInsType_effRVal,
//                         ceilingInsType_nomRVal: componentData.ceilingInsType_nomRVal
//                     }
//                 }
//             }
//         } else if (componentType === 'wall') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                     fields: {
//                         wallInsType: componentData.wallInsType,
//                         wallInsType_effRVal: componentData.wallInsType_effRVal,
//                         wallInsType_nomRVal: componentData.wallInsType_nomRVal,
//                         lintelInsType: componentData.lintelInsType,
//                     }
//                 }
//             }
//         } else if (componentType === 'expFloor') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                     fields: {
//                         expFloorInsType: componentData.expFloorInsType,
//                         expFloorInsType_effRVal: componentData.expFloorInsType_effRVal,
//                         expFloorInsType_nomRVal: componentData.expFloorInsType_nomRVal,
//                     }
//                 }
//             }
//         } else if (componentType === 'floorHeader') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                     fields: {
//                         floorHeaderInsType: componentData.floorHeaderInsType,
//                         floorHeaderInsType_effRVal: componentData.floorHeaderInsType_effRVal,
//                         floorHeaderInsType_nomRVal: componentData.floorHeaderInsType_nomRVal,
//                     }
//                 }
//             }
//         } else if (componentType === 'window') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                     fields: {
//                         windowType: componentData.windowType,
//                         windowType_effRVal: componentData.windowType_effRVal,
//                         windowType_nomRVal: componentData.windowType_nomRVal,
//                         shgc: componentData.shgc,
//                         er: componentData.er,
//                     }
//                 }
//             }
//         } else if (componentType === 'door') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                         fields: {
//                         doorType: componentData.doorType,
//                         energyStar: componentData.energyStar,
//                     }
//                 }
//             }
//         } else if (componentType === 'basement') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                     fields: {
//                         wall: {
//                             extAddedInsType: componentData?.wall?.extAddedInsType,
//                             extAddedInsType_composite: componentData?.wall?.extAddedInsType_composite,
//                             intAddedInsType: componentData?.wall?.intAddedInsType,
//                             intAddedInsType_nomRVal: componentData?.wall?.intAddedInsType_nomRVal,
//                             intAddedInsType_effRVal: componentData?.wall?.intAddedInsType_effRVal,
//                             intAddedInsType_composite: componentData?.wall?.intAddedInsType_composite,
//                             lintelInsType: componentData?.wall?.lintelInsType,
//                             ponyWall: componentData?.wall?.ponyWall,
//                         },
//                         floor: {
//                             floorsAboveInsType: componentData?.floor?.floorsAboveInsType,
//                             floorsAboveInsType_nomRVal: componentData?.floor?.floorsAboveInsType_nomRVal,
//                             floorsAboveInsType_effRVal: componentData?.floor?.floorsAboveInsType_effRVal,
//                             slabInsType: componentData?.floor?.slabInsType,
//                             slabInsType_nomRVal: componentData?.floor?.slabInsType_nomRVal,
//                             slabInsType_effRVal: componentData?.floor?.slabInsType_effRVal,
//                         }
//                     }
//                 }
//             }
//         } else if (componentType === 'crawlspace') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                     fields: {
//                         wall: {
//                             crawlspaceWallInsType: componentData?.wall?.crawlspaceWallInsType,
//                             crawlspaceWallInsType_nomRVal: componentData?.wall?.crawlspaceWallInsType_nomRVal,
//                             crawlspaceWallInsType_effRVal: componentData?.wall?.crawlspaceWallInsType_effRVal,
//                             crawlspaceWallInsType_composite: componentData?.wall?.crawlspaceWallInsType_composite,
//                             lintelInsType: componentData?.wall?.lintelInsType
//                         },
//                         floor: {
//                             floorsAboveInsType: componentData?.floor?.floorsAboveInsType,
//                             floorsAboveInsType_nomRVal: componentData?.floor?.floorsAboveInsType_nomRVal,
//                             floorsAboveInsType_effRVal: componentData?.floor?.floorsAboveInsType_effRVal,
//                             slabInsType: componentData?.floor?.slabInsType,
//                             slabInsType_nomRVal: componentData?.floor?.slabInsType_nomRVal,
//                             slabInsType_effRVal: componentData?.floor?.slabInsType_effRVal,
//                         }
//                     }
//                 }
//             }
//         } else if (componentType === 'slab') {
//             return {
//                 ...cache,
//                 [accessor]: {
//                     fields: {
//                         floor: {
//                             slabInsType: componentData?.floor?.slabInsType,
//                             slabInsType_nomRVal: componentData?.floor?.slabInsType_nomRVal,
//                             slabInsType_effRVal: componentData?.floor?.slabInsType_effRVal,
//                         }
//                     }
//                 }
//             }
//         }
//         return cache
//     }, {})
// };

const calcEla = (houseVolume, ach) => {
    const slope = 0.344224 * houseVolume - 0.0054;
    const intercept = 0.043961 * houseVolume + 0.026977;

    return parseFloat((slope * ach + intercept).toFixed(2));
};

export const getInitAirtightUpgradeFields = (airTightness, dimensions) => {
    //Get airtightness default
    const { volume: { total: totalVolume = 0 } = {} } = dimensions;

    //update airTightnessDefault based on h2k formula
    const { blowerTest: { airChangeRate50Pa = 0 } = {}, blowerTest = {} } = airTightness;
    const upgradedAirtightness = Math.min(
        0.9 * airChangeRate50Pa,
        0.0004 * airChangeRate50Pa ** 3 - 0.0312 * airChangeRate50Pa ** 2 + 1.0935 * airChangeRate50Pa
    );
    return {
        fields: {
            ...airTightness,
            testType: { id: 0 },
            blowerTest: {
                ...blowerTest,
                airChangeRate50Pa: parseFloat(upgradedAirtightness.toFixed(2)),
                eqLeakageArea: calcEla(totalVolume, upgradedAirtightness),
            },
        },
    };
};

// this needs to be changed to fit new cost structure in the state
const getTotalCapitalCost = (upgradePackage, numberFormat = false) => {
    const packageKey = Object.keys(upgradePackage)[0];
    const { upgrades = {} } = upgradePackage[packageKey] || {};

    const totalCost = Object.values(upgrades).reduce((sum, curr) => {
        const { cost = {} } = curr;
        return sum + Object.values(cost).reduce((partialSum, { total = 0 }) => partialSum + total, 0);
    }, 0);

    if (numberFormat) {
        return totalCost;
    }

    return dollarFormatter.format(totalCost);
};

const getGrantLabel = (upgradePackage, returnType = "base") => {
    const packageKey = Object.keys(upgradePackage)[0];
    const { upgrades = {} } = upgradePackage[packageKey] || {};

    let { grantObject = {}, upgradeKeys } = getGrantObject(upgradePackage);

    const sum = !isEmpty(grantObject)
        ? Object.values(grantObject).reduce((acc, grant) => {
              return acc + grant.amount;
          }, 0)
        : 0;

    const description = !isEmpty(grantObject)
        ? Object.values(grantObject).reduce((acc, grant) => {
              if (grant.grantType) {
                  return [...acc, `${grant.grantType}`];
              } else {
                  return acc;
              }
          }, "")
        : "";

    const string = () => {
        if (sum !== 0 && Object.keys(grantObject).length < 3) {
            return `${dollarFormatter.format(sum)} ${description ? `(${description})` : ""}`;
        } else if (sum !== 0 && Object.keys(grantObject).length > 2) {
            return `${dollarFormatter.format(sum)} (${Object.keys(grantObject).length} Grants)`;
        } else {
            return "-";
        }
    };
    return {
        label: string(),
        upgradeIds: upgradeKeys,
    };
};

const getLoanLabel = (upgradePackage, returnType = "base") => {
    let { loanObject = {}, upgradeKeys } = getLoanObject(upgradePackage);

    let string = "-";
    const firstLoan = Object.values(loanObject)[0];
    if (Object.values(loanObject).length < 2 && Object.values(loanObject).length > 0) {
        const rate = firstLoan.rate ? ` at ${firstLoan.rate}%` : ` at 0%`;
        const term = firstLoan.term ? ` for ${firstLoan.term}y` : "";
        const adminFee = firstLoan.adminFee ? `, ${firstLoan.adminFee}% fee` : "";
        string = `${dollarFormatter.format(firstLoan.amount)}${rate}${term}${adminFee}`;
    } else if (Object.values(loanObject).length > 1) {
        const totalAmount = Object.values(loanObject).reduce((acc, loan) => {
            return acc + loan.amount;
        }, 0);
        string = `${dollarFormatter.format(totalAmount)} (${Object.keys(loanObject).length} loans)`;
    }

    const loanLabel = !isEmpty(loanObject) ? string : "-";

    if (returnType === "upgrade") {
        return {
            label: loanLabel,
            upgradeIds: upgradeKeys,
        };
    }

    return {
        label: "-",
        upgradeIds: [],
    };
};

const getValuationParamsLabel = (upgradePackage, returnType = "base") => {
    if (returnType === "base") {
        return {
            label: "-",
            upgradeIds: [],
        };
    }
    let { financialValuationObj = {}, upgradeKeys } = getFinancialValParams(upgradePackage);
    const { financialValuation: { discountRate, projectDuration } = {} } = financialValuationObj || {};

    const paramsLabel = `${parseInt(projectDuration)} y @ ${discountRate.toFixed(2)}%`;

    return {
        label: paramsLabel,
        upgradeIds: upgradeKeys,
    };
};

export const upgradesBuilder = (upgradePackage, packageId, modelComponents, modelChange, modelParams = {}) => {
    const { upgrades = {} } = upgradePackage || {};
    const { dimensions: { volume: { total: totalHouseVolume } = {} } = {} } = modelParams;

    let upgradesToRemove = [];

    const upgradesKeys = Object.keys(upgrades);

    let upgradeTypes = {};

    envelopeRowHeadings.map((rowObj) => {
        const { upgradeIds } = getUpgradeEnvelopeRowLabels({
            components: {},
            codes: {},
            airtightness: {},
            rowId: rowObj.id,
            upgradePackage: {
                [packageId]: upgradePackage,
            },
        });

        const isUpgradeIdsExist = upgradeIds ? (upgradeIds.length > 0 ? true : false) : false;

        if (isUpgradeIdsExist) {
            upgradeTypes = {
                ...upgradeTypes,
                [upgradeIds[0]]: rowObj.id,
            };
        }
    });

    const { basement: basementsInModel = {} } = modelComponents;
    const modelContainsBasement = !isEmpty(
        Object.keys(basementsInModel).filter((id) => !isEmpty(basementsInModel[id]))
    );

    for (let i = 0; i < upgradesKeys.length; i++) {
        const upgrade = upgradesKeys[i];

        const { upgradeType: upgradeH2kType, codes = {}, cost = {} } = upgrades[upgrade];

        const upgradeType = upgradeTypes[upgrade];

        if (upgradeH2kType === "domesticHotWater" && !modelContainsBasement) {
            const { primarySystem = {}, secondarySystem = {} } = upgradePackage.upgrades[upgrade].fields || {};

            if (!isEmpty(primarySystem)) {
                upgradePackage.upgrades[upgrade].fields.primarySystem.tankLocation = { id: 0 };
            }
            if (!isEmpty(secondarySystem)) {
                upgradePackage.upgrades[upgrade].fields.secondarySystem.tankLocation = { id: 0 };
            }
        }

        if (upgradeH2kType === "airTightness") {
            const { blowerTest: { isEqLeakageAreaCalculated, airChangeRate50Pa } = {} } =
                upgradePackage.upgrades[upgrade].fields || {};
            if (isEqLeakageAreaCalculated) {
                upgradePackage.upgrades[upgrade].fields.blowerTest.eqLeakageArea = calcEla(
                    totalHouseVolume,
                    airChangeRate50Pa
                );
            }
        }

        //Update modelCodes for all codes present, do this here because we were occasionally hitting continue statements before this ran

        Object.keys(codes).forEach((codeRef) => {
            modelChange(`modelData.codes.${codeRef}`, codes[codeRef]);
        });

        let availableComponents = ["window", "door", "floorHeader"].includes(upgradeH2kType)
            ? getSubComponentOptions(upgradeH2kType, modelComponents, upgrades, upgrade)
            : getComponentOptions(upgradeH2kType, modelComponents, upgrades, upgrade);

        if (upgradeType === "window" || upgradeType === "skylights") {
            availableComponents = stripWindowSkylights(availableComponents, upgradeType);
        } else if (upgradeType === "ceilingWithAttic" || upgradeType === "cathedral" || upgradeH2kType === "ceiling") {
            availableComponents = stripCeilings(availableComponents, modelComponents, upgradeType);
        }

        if (upgradeType !== undefined || upgradeH2kType === "window") {
            if (!availableComponents.length && upgradeH2kType !== "airTightness") {
                upgradesToRemove.push(upgrade);

                continue;
            }

            if (upgradeH2kType === "airTightness") {
                modelChange(`modelData.${upgradeH2kType}.upgradePackages.${packageId}`, { upgradeId: upgrade });
            }

            if (!!availableComponents.length) {
                if (["ceiling", "basement", "crawlspace", "slab"].includes(upgradeH2kType)) {
                    for (let j = 0; j < availableComponents.length; j++) {
                        const component = availableComponents[j];

                        let id = upgrade;
                        const selectedComponents = [component.value];
                        if (j === 0) {
                            upgradePackage.upgrades[id].selectedComponents = selectedComponents;

                            modelChange(`modelData.components.${component.value}.upgradePackages.${packageId}`, {
                                upgradeId: id,
                            });
                        } else {
                            id = uniqid("upgrade-");

                            upgradePackage.upgrades = {
                                ...upgradePackage.upgrades,
                                [id]: { ...upgradePackage.upgrades[upgrade], selectedComponents: selectedComponents },
                            };

                            modelChange(`modelData.components.${component.value}.upgradePackages.${packageId}`, {
                                upgradeId: id,
                            });
                        }

                        //Apply costs
                        const tableUpgradeTypes = Object.keys(cost).filter((key) => !["total", "items"].includes(key)); //Not necessary once bug is fixed

                        if (!isEmpty(selectedComponents) && !isEmpty(tableUpgradeTypes)) {
                            tableUpgradeTypes.forEach((tableUpgradeType) => {
                                let { [tableUpgradeType]: { total = 0, items: costItems = {} } = {} } = cost;
                                const totalComponentArea = parseFloat(
                                    getCompAreaForCostCalc(selectedComponents, modelComponents, upgradeType).toFixed(2)
                                );

                                const { totalCost = 0, costItems: newCostItems } = calcUpgradeDetailedCost(
                                    costItems,
                                    totalComponentArea
                                );

                                upgradePackage.upgrades[upgrade].cost[tableUpgradeType] = {
                                    total: totalCost,
                                    items: newCostItems,
                                };
                            });
                        }
                    }

                    continue;
                }

                const selectedComponents = availableComponents.map(({ value }) => {
                    modelChange(`modelData.components.${value}.upgradePackages.${packageId}`, { upgradeId: upgrade });

                    return value;
                });

                upgradePackage.upgrades[upgrade].selectedComponents = selectedComponents;

                //Apply costs
                const tableUpgradeTypes = Object.keys(cost).filter((key) => !["total", "items"].includes(key)); //Not necessary once bug is fixed

                if (!isEmpty(selectedComponents) && !isEmpty(tableUpgradeTypes)) {
                    tableUpgradeTypes.forEach((tableUpgradeType) => {
                        let { [tableUpgradeType]: { total = 0, items: costItems = {} } = {} } = cost;
                        const totalComponentArea = parseFloat(
                            getCompAreaForCostCalc(selectedComponents, modelComponents, upgradeType).toFixed(2)
                        );

                        const { totalCost = 0, costItems: newCostItems } = calcUpgradeDetailedCost(
                            costItems,
                            totalComponentArea
                        );

                        upgradePackage.upgrades[upgrade].cost[tableUpgradeType] = {
                            total: totalCost,
                            items: newCostItems,
                        };
                    });
                }

                continue;
            }
        }

        //TODO: handle case for generation system size
        if (upgradeType === "generation") {
        }

        modelChange(`modelData.${upgradeH2kType}.upgradePackages.${packageId}`, { upgradeId: upgrade });
    }

    for (let i = 0; i < upgradesToRemove.length; i++) {
        delete upgradePackage.upgrades[upgradesToRemove[i]];
    }

    return upgradePackage;
};

export const calcUpgradeDetailedCost = (costItems, totalUnitValue) => {
    const cleanCostArray = Object.entries(costItems)
        .map(([key, val]) => ({ key, ...val }))
        .filter(({ total = 0, tax = 0 }) => {
            return !isNaN(total) && !isNaN(tax);
        });

    let taxFraction = 0;
    let postTaxRebate = 0;
    const preTaxTotal = cleanCostArray.reduce((sum, { key = "", total = 0, tax = 0, pricePerUnit = 0, label }) => {
        if (label === "tax") {
            taxFraction += tax / 100;
            return sum;
        } else if (label === "preTaxRebate") {
            return sum - total;
        } else if (label === "postTaxRebate") {
            postTaxRebate += total;
            return sum;
        } else if (pricePerUnit > 0 && totalUnitValue != null) {
            //We only hit this case if we explicitly provide a value to apply the per unit cost to
            //Otherwise, we assume the total has already been calculated (like in the table itself)

            const newTotal = pricePerUnit * totalUnitValue;

            costItems[key].total = newTotal;

            return sum + newTotal;
        } else {
            return sum + total;
        }
    }, 0);

    const totalCost = preTaxTotal + preTaxTotal * taxFraction - postTaxRebate || 0;

    return {
        totalCost,
        preTaxTotal,
        taxFraction,
        postTaxRebate,
        costItems,
    };
};

export const getBlankUpgradeTemplates = (selectedUpgradeType) => {
    const templates = {
        bathroomFaucets: baseLoadsDefaults,
        showerHeads: baseLoadsDefaults,
        clothesWasher: baseLoadsDefaults,
        dishwasher: baseLoadsDefaults,
        lighting: baseLoadsDefaults,
        monitoringSys: baseLoadsDefaults,
        primaryHotWater: domesticHotWaterDefaults,
        dwhr: domesticHotWaterDefaults,
        generation: generationDefaults,
        spaceHeating: heatingCoolingDefaults,
        spaceCooling: heatingCoolingDefaults,
        secondaryHeating: heatingCoolingDefaults,
        ventilation: ventilationDefaults,
    };

    return templates?.[selectedUpgradeType] || {};
};

export const getBlankComponentUpgradeFields = (type) => {
    if (type === "ceilingWithAttic") {
        return {
            fields: {
                constructionType: { id: 0 },
                ceilingInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                ceilingInsType_effRVal: 0.1,
                ceilingInsType_nomRVal: 0.1,
            },
        };
    } else if (type === "cathedral") {
        return {
            fields: {
                constructionType: { id: 2 },
                ceilingInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                ceilingInsType_effRVal: 0.1,
                ceilingInsType_nomRVal: 0.1,
            },
        };
    } else if (type === "wall") {
        return {
            fields: {
                wallInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                wallInsType_effRVal: 0.1,
                wallInsType_nomRVal: 0.1,
                lintelInsType: {},
            },
        };
    } else if (type === "expFloor") {
        return {
            fields: {
                expFloorInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                expFloorInsType_effRVal: 0.1,
                expFloorInsType_nomRVal: 0.1,
            },
        };
    } else if (type === "floorHeader") {
        return {
            fields: {
                floorHeaderInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                floorHeaderInsType_effRVal: 0.1,
                floorHeaderInsType_nomRVal: 0.1,
            },
        };
    } else if (type === "window" || type === "skylights") {
        return {
            fields: {
                windowType: {},
                windowType_effRVal: 0.1,
                windowType_nomRVal: 0.1,
                shgc: 0,
                er: 0,
            },
        };
    } else if (type === "door") {
        return {
            fields: {
                doorType: { id: 4, value: 1.14 },
                energyStar: false,
            },
        };
    } else if (type === "basementWall" || type === "basementSlab") {
        return {
            fields: {
                wall: {
                    extAddedInsType: { id: 10, value: 0 },
                    extAddedInsType_composite: {},
                    intAddedInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    intAddedInsType_nomRVal: 0,
                    intAddedInsType_effRVal: 0,
                    intAddedInsType_composite: {},
                    lintelInsType: {},
                    ponyWall: {
                        enabled: false,
                        ponyWallHeight: 0,
                        ponyWallInsType: {},
                        ponyWallInsType_effRVal: 0,
                        ponyWallInsType_nomRVal: 0,
                    },
                },
                floor: {
                    floorsAboveInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    floorsAboveInsType_nomRVal: 0.1,
                    floorsAboveInsType_effRVal: 0.1,
                    slabInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    slabInsType_nomRVal: 0,
                    slabInsType_effRVal: 0,
                },
            },
        };
    } else if (type === "crawlspace") {
        return {
            fields: {
                wall: {
                    crawlspaceWallInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    crawlspaceWallInsType_nomRVal: 0.1,
                    crawlspaceWallInsType_effRVal: 0.1,
                    crawlspaceWallInsType_composite: {},
                    lintelInsType: {},
                },
                floor: {
                    floorsAboveInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    floorsAboveInsType_nomRVal: 0.1,
                    floorsAboveInsType_effRVal: 0.1,
                    slabInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    slabInsType_nomRVal: 0,
                    slabInsType_effRVal: 0,
                },
            },
        };
    } else if (type === "slab") {
        return {
            fields: {
                floor: {
                    slabInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    slabInsType_nomRVal: 0,
                    slabInsType_effRVal: 0,
                },
            },
        };
    } else if (type === "airTightness") {
        return {
            fields: airTightnessDefaults,
        };
    }
    return { fields: {} };
};
