import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import Button from "components/Button";
import Drawer from "components/Drawer";
import LineItems from "./LineItems/container";
import buildReviewTable from "utils/review/buildReviewTable";
import Checkbox from "components/Input/Checkbox";
import CalendarIcon from "assets/images/icons/JSX/Calculator";
import { isEmpty } from "lodash";
import { airTestZoneInfo } from "features/Model/Airtightness/templates/airTestZoneInfo";

const agFloorAreaValidation = getValidation("agHeatedFloorArea");
const bgFloorAreaValidation = getValidation("bgHeatedFloorArea");
const volumeValidation = getValidation("totalHouseVolume");
const windowToWallValidation = getValidation("windowToWallRatio");
const highestCeilingValidation = getValidation("agHeightHighestCeiling");
const nonResHeatedFloorAreaValidation = getValidation("nonResHeatedFloorArea");
const commonHeatedFloorAreaValidation = getValidation("commonHeatedFloorArea");

const getDrawerText = (activeDrawer) => {
    switch (activeDrawer) {
        case "aboveGradeIntFloorArea":
            return {
                header: "Above Grade Heated Floor Area",
                subtitle: "floor area",
            };
        case "belowGradeIntFloorArea":
            return {
                header: "Below Grade Heated Floor Area",
                subtitle: "floor area",
            };
        case "volume":
            return {
                header: "Total House Volume",
                subtitle: "volume",
            };
        default:
            return {
                header: "",
                subtitle: "",
            };
    }
};

export default ({
    accessor,
    activeDrawer,
    agFloorAreaTotalValue,
    agFloorAreaUnits,
    bgFloorAreaTotalValue,
    bgFloorAreaUnits,
    volumeTotalValue,
    volumeUnits,
    modelUnits,
    components,
    windowToWallValue,
    isCrawlspacePresent,
    heatedCrawlspaceNoVolume,
    isBasementPresent,
    highestCeilingUnits,
    nonResidentialFloorAreaUnits,
    commonHeatedFloorAreaUnits,
    change,
    highestCeilingDrawing,
    highestCeilingValue,
    highestCeilingEquation,
    nonResidentialFloorAreaEqn,
    commonHeatedFloorAreaEqn,
    hasFloorItems,
    isAirTestEnabled = false,
    testData,
    testTypeId,
    volumeItemsSum,
    buildingType,
    nonResUnitCount,
}) => {
    const [open, toggleOpen] = useState(false);
    // const

    useEffect(() => {
        if (isAirTestEnabled && [1, 2, 3, 4].includes(testTypeId)) {
            const enforcedZoneVolume = Object.keys(airTestZoneInfo[testTypeId]).reduce(
                (sumVol, testKey) => sumVol + (testData?.tests?.[testKey]?.zoneHeatedVolume || 0),
                0
            );
            change(`${accessor}.volume.total`, enforcedZoneVolume || volumeTotalValue);
        } else if (volumeItemsSum !== volumeTotalValue) {
            change(`${accessor}.volume.total`, volumeItemsSum == null ? volumeTotalValue : volumeItemsSum);
        }
    }, [isAirTestEnabled, testData, testTypeId, volumeItemsSum, accessor, volumeTotalValue]);

    const getFieldKey = (activeDrawer) => {
        switch (activeDrawer) {
            case "belowGradeIntFloorArea":
                return "bgHeatedFloorArea";

            case "commonHeatedFloorArea":
                return "commonHeatedFloorArea";

            case "nonResidentialFloorArea":
                return "nonResHeatedFloorArea";

            case "volume":
                return "totalHouseVolume";

            default:
                return "agHeatedFloorArea";
        }
    };

    let volumeEditProps = {};

    if (!(isAirTestEnabled && [1, 2, 3, 4].includes(testTypeId))) {
        volumeEditProps.editToggle = () => {
            change(`${accessor}.activeDrawer`, "volume");
            toggleOpen(true);
        };
    }

    return (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.aboveGradeIntFloorArea.total`}
                    label="Above Grade Heated Floor Area"
                    placeholder={0.0}
                    validate={agFloorAreaValidation}
                    decimals={2}
                    change={change}
                    disabled={true}
                    setValue={agFloorAreaTotalValue}
                    units={{
                        base: getBaseUnits("agHeatedFloorArea", modelUnits),
                        options: ["m2", "ft2"],
                        selected: agFloorAreaUnits,
                        unitType: "area",
                        accessor: `${accessor}.aboveGradeIntFloorArea_u`,
                    }}
                    editToggle={() => {
                        change(`${accessor}.activeDrawer`, "aboveGradeIntFloorArea");
                        toggleOpen(true);
                    }}
                    warning={bgFloorAreaTotalValue + agFloorAreaTotalValue < 10 ? "floorAreaUnder10m2" : ""}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.belowGradeIntFloorArea.total`}
                    label="Below Grade Heated Floor Area"
                    placeholder={0.0}
                    validate={bgFloorAreaValidation}
                    decimals={2}
                    change={change}
                    disabled={true}
                    setValue={bgFloorAreaTotalValue}
                    units={{
                        base: getBaseUnits("bgHeatedFloorArea", modelUnits),
                        options: ["m2", "ft2"],
                        selected: bgFloorAreaUnits,
                        unitType: "area",
                        accessor: `${accessor}.belowGradeIntFloorArea_u`,
                    }}
                    editToggle={() => {
                        change(`${accessor}.activeDrawer`, "belowGradeIntFloorArea");
                        toggleOpen(true);
                    }}
                    warning={isBasementPresent && bgFloorAreaTotalValue === 0 ? "basementNoBgFloorArea" : ""}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.volume.total`}
                    label="Total House Volume"
                    placeholder={0.0}
                    validate={volumeValidation}
                    decimals={2}
                    change={change}
                    disabled={true}
                    setValue={parseFloat(volumeTotalValue.toFixed(2))}
                    units={{
                        base: getBaseUnits("totalHouseVolume", modelUnits),
                        options: ["m3", "ft3"],
                        selected: volumeUnits,
                        unitType: "volume",
                        accessor: `${accessor}.volume_u`,
                    }}
                    {...volumeEditProps}
                    warning={
                        volumeTotalValue === 0
                            ? "zeroTotalVolume"
                            : heatedCrawlspaceNoVolume
                            ? "heatedCrawlspaceNoVolume"
                            : ""
                    }
                    info={
                        isAirTestEnabled && [1, 2, 3, 4].includes(testTypeId)
                            ? "This field is controlled by the heated zone volumes specified in the Airtightness tab. To view previous values, disable the air leakage test data checkbox."
                            : null
                    }
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`modelData.dimensions.highestCeiling.total`}
                    label="Height of Highest Ceiling"
                    placeholder="0.00"
                    validate={highestCeilingValidation}
                    disabled={highestCeilingDrawing}
                    isDrawing={highestCeilingDrawing}
                    setValue={highestCeilingValue}
                    decimals={getDecimalPlaces("agHeightHighestCeiling")}
                    change={change}
                    withEquation
                    equation={highestCeilingEquation}
                    units={{
                        base: getBaseUnits("agHeightHighestCeiling", modelUnits),
                        options: getUnitOptions("length"),
                        selected: highestCeilingUnits,
                        unitType: "length",
                        accessor: `modelData.dimensions.highestCeiling.total_u`,
                    }}
                    info={
                        "This field requires the total vertical distance between the lowest grade point around the house exterior and the highest interior ceiling surface point on the upper-most level."
                    }
                />
            </InputRow>
            {buildingType === "wholeMurb" && (
                <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                    <Field
                        label="Non-Res Unit Area (MURB)"
                        name="modelData.dimensions.nonResidentialFloorArea.total"
                        component={InputWithUnits}
                        type="number"
                        placeholder="Enter a value"
                        validate={nonResHeatedFloorAreaValidation}
                        decimals={getDecimalPlaces("nonResHeatedFloorArea")}
                        change={change}
                        withEquation
                        equation={nonResidentialFloorAreaEqn}
                        units={{
                            base: getBaseUnits("nonResHeatedFloorArea", modelUnits),
                            options: getUnitOptions("area"),
                            selected: nonResidentialFloorAreaUnits,
                            unitType: "area",
                            accessor: `modelData.dimensions.nonResidentialFloorArea.total_u`,
                        }}
                        warning={nonResUnitCount === 0 ? "nonResFloorAreaCountMismatch" : null}
                    />
                    <Field
                        label="Common Space Area (MURB)"
                        name="modelData.dimensions.commonHeatedFloorArea.total"
                        component={InputWithUnits}
                        type="number"
                        placeholder="Enter a value"
                        validate={commonHeatedFloorAreaValidation}
                        decimals={getDecimalPlaces("commonHeatedFloorArea")}
                        change={change}
                        withEquation
                        equation={commonHeatedFloorAreaEqn}
                        units={{
                            base: getBaseUnits("commonHeatedFloorArea", modelUnits),
                            options: getUnitOptions("area"),
                            selected: commonHeatedFloorAreaUnits,
                            unitType: "area",
                            accessor: `modelData.dimensions.commonHeatedFloorArea.total_u`,
                        }}
                    />
                </InputRow>
            )}
            <Drawer
                open={open}
                close={() => toggleOpen(false)}
                preTitle="Calculator"
                title={`${getDrawerText(activeDrawer).header}`}
                subtitle={`Add, subtract, or modify ${getDrawerText(activeDrawer).subtitle} components.`}
                //preIcon={CalendarIcon}
            >
                <div>
                    <LineItems
                        drawerName={getDrawerText(activeDrawer).header}
                        accessor={`${accessor}.${activeDrawer}`}
                        change={change}
                        fieldKey={getFieldKey(activeDrawer)}
                        hasFloorItems={hasFloorItems}
                    />
                    {activeDrawer === "volume" && (
                        <Field
                            className={classes.includesVolume}
                            component={Checkbox}
                            name={`modelData.dimensions.volume.includeCrawlspaceVolume`}
                            label="Includes crawlspace volume"
                            large
                            type="checkbox"
                            disabled={!isCrawlspacePresent}
                            style={{ alignSelf: "flex-end" }}
                        />
                    )}
                    <div className={classes.buttons}>
                        <Button large onClick={() => toggleOpen(false)}>
                            Close
                        </Button>
                    </div>
                </div>
            </Drawer>
        </>
    );
};
