import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import General from "./";

import { actions as modelActions } from "features/Model/_ducks";

const { addTagToModelFile } = modelActions;

const mapStateToProps = ({
    form,
    model: { contentOpen = false, modelDetails: { tags = [] }, modelId } = {},
    user: { nonErsUser = false, userPermissions: { CHBA_NZEA = false } = {}, uid } = {},
}) => {
    const selector = formValueSelector("model");
    const programClass = selector({ form }, "modelData.program.class") || "";
    const { id: buildingTypeId } = selector({ form }, "modelData.specifications.buildingType") || 0;
    const { id: buildingSubTypeId } = selector({ form }, "modelData.specifications.buildingSubType") || 0;
    const { id: facingId } = selector({ form }, "modelData.specifications.facingDirection") || 0;
    const { id: weatherProvTerrId } = selector({ form }, "modelData.location.weather.region") || 0;
    const { id: weatherLocationId, value: weatherHddValue } =
        selector({ form }, "modelData.location.weather.location") || {};
    const { id: f280LocationId } = selector({ form }, "modelData.location.weather.f280.location") || {};
    const { major: h2kMajor, minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};

    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`);
    const depthOfFrostUnits = selector({ form }, "modelData.location.weather.depthOfFrostline_u");
    const f280HeatingDesignTempUnits = selector({ form }, "modelData.location.weather.f280.dhdbt_u");
    const f280CoolingDesignTempUnits = selector({ form }, "modelData.location.weather.f280.dcdbt_u");

    const isSingleMURB = buildingTypeId === 1;
    const isWholeBuildingMURB = buildingTypeId === 2;

    const vermiculiteValue = selector({ form }, `modelData.program.options.main.vermiculitePresence`) || {};

    const bedroomsEntered = selector({ form }, "modelData.ventilation.rooms.bedrooms") || 0;
    const agFloorAreaTotalValue = selector({ form }, `modelData.dimensions.aboveGradeIntFloorArea.total`) || 0;
    const bgFloorAreaTotalValue = selector({ form }, `modelData.dimensions.belowGradeIntFloorArea.total`) || 0;

    const f280Warning = selector({ form }, "modelData.location.weather.f280.location_warning") || "";

    const { id: chbaLabelId } = selector({ form }, "modelData.chbaNz.file.targetLabel") || {};

    const selectedChbaLabelType = [0, 1, 2, 3].includes(chbaLabelId)
        ? {
              0: "CHBA_NZ",
              1: "CHBA_NZ_READY",
              2: "CHBA_NZ_RENO",
              3: "CHBA_NZ_READY_RENO",
          }[chbaLabelId]
        : null;

    const numDwellingUnits = selector({ form }, "modelData.specifications.murbSpecs.numDwellingUnits") || 0;
    const numNonResUnits = selector({ form }, "modelData.specifications.murbSpecs.numNonResUnits") || 0;

    const murbUnitCount = numDwellingUnits + numNonResUnits;

    return {
        modelId,
        uid,
        buildingTypeId,
        buildingSubTypeId,
        facingId,
        weatherProvTerrId,
        weatherLocationId,
        f280LocationId,
        weatherHddValue,
        depthOfFrostUnits,
        f280HeatingDesignTempUnits,
        f280CoolingDesignTempUnits,
        modelUnits,
        h2kVersion: `v${h2kMajor}-${h2kMinor}`,
        h2kMinor,
        isSingleMURB,
        isWholeBuildingMURB,
        programClass,
        vermiculiteValue,
        bedroomsEntered,
        f280Warning,
        contentOpen,
        CHBA_NZEA,
        selectedChbaLabelType,
        floorArea: agFloorAreaTotalValue + bgFloorAreaTotalValue,
        nonErsUser,
        murbUnitCount,
    };
};

const mapDispatchToProps = (dispatch) => ({
    addTagToModelFile: async (modelId, uid, tag, taggingType) =>
        dispatch(addTagToModelFile(modelId, uid, tag, taggingType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(General);
