import React from "react";
import classes from "./style.module.scss";
import { Field } from "redux-form";
import { getOptions, getBaseUnits, getUnitOptions } from "utils/fields";
import { maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";

const charLim100 = maxLength(100);
const acEquipTypeValidation = getValidation("acEquipType");
const acCapacityValidation = getValidation("acOutputCapacity");
const acCoolingEffValidation = getValidation("acEff");
const acCrankcaseHeaterValidation = getValidation("acCrankcaseHeater");
const sensibleHeatRatioValidation = getValidation("acSensibleHeatRatio");
const openableWindowAreaValidation = getValidation("acOpenableWindowArea");
const acSizingFactorValidation = getValidation("acSizingFactor");

export default ({
    accessor,
    capacityId,
    capacityValue,
    capacityUnits,
    crankcaseHeaterUnits,
    openableWindowAreaUnits,
    modelUnits,
    change,
    h2kMinor,
}) => {
    return (
        <>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    name={`${accessor}.system.airConditioning.manufacturer`}
                    type="text"
                    label="Manufacturer Name"
                    placeholder="Enter Manufacturer Name"
                    validate={charLim100}
                />
                <Field
                    component={Input}
                    name={`${accessor}.system.airConditioning.model`}
                    type="text"
                    label="Model Name"
                    placeholder="Enter Model Name"
                    validate={charLim100}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.system.airConditioning.energyStar`}
                    label="Energy Star"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.airConditioning.equipmentType`}
                    options={getOptions({ fieldKey: "acEquipType", version: `v11-${h2kMinor}` })}
                    label="Central Equipment Type"
                    placeholder="Choose Equipment Type"
                    validate={acEquipTypeValidation}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.system.airConditioning.specifications.outputCapacity`}
                    options={[
                        {
                            label: "Calculated",
                            value: {
                                id: 0,
                                value: capacityValue,
                                uiUnits: "kW",
                            },
                        },
                        {
                            label: "User Specified",
                            value: {
                                id: 1,
                                value: capacityValue,
                                uiUnits: "kW",
                            },
                        },
                    ]}
                    label="Capacity Type"
                    placeholder="Choose Input Type"
                    validate={acCapacityValidation}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.airConditioning.specifications.outputCapacity.value`}
                    label="Output Capacity"
                    placeholder="0.00"
                    validate={acCapacityValidation}
                    decimals={getDecimalPlaces(`acOutputCapacity`)}
                    change={change}
                    disabled={capacityId !== 1}
                    units={{
                        base: getBaseUnits(`acOutputCapacity`, modelUnits),
                        options: getUnitOptions("power"),
                        selected: capacityUnits,
                        unitType: "power",
                        accessor: `${accessor}.system.airConditioning.specifications.outputCapacity_u`,
                    }}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.airConditioning.specifications.sizingFactor`}
                    label="Sizing Factor"
                    placeholder={0.0}
                    validate={acSizingFactorValidation}
                    decimals={getDecimalPlaces("acSizingFactor")}
                />
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.airConditioning.specifications.coolingParams.sensibleHeatRatio`}
                    label="Sensible Heat Ratio"
                    placeholder="0.00"
                    validate={sensibleHeatRatioValidation}
                    decimals={getDecimalPlaces(`acSensibleHeatRatio`)}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.airConditioning.specifications.coolingParams.openableWindowArea`}
                    label="Openable Window Area"
                    placeholder="0.00"
                    validate={openableWindowAreaValidation}
                    decimals={getDecimalPlaces(`acOpenableWindowArea`)}
                    change={change}
                    units={{
                        base: getBaseUnits(`acOpenableWindowArea`, modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: openableWindowAreaUnits,
                        unitType: "fraction",
                        accessor: `${accessor}.system.airConditioning.specifications.coolingParams.openableWindowArea_u`,
                    }}
                    info={"This field must be left at the default value (0%) for all EnerGuide files."}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Input}
                    type="number"
                    name={`${accessor}.system.airConditioning.specifications.coolingEfficiency.value`}
                    label="Cooling Efficiency"
                    placeholder="0.00"
                    validate={acCoolingEffValidation}
                    decimals={getDecimalPlaces("acEff")}
                />
                <Field
                    component={Select}
                    name={`${accessor}.system.airConditioning.specifications.coolingEfficiency.isCop`}
                    options={[
                        {
                            label: "COP",
                            value: true,
                        },
                        {
                            label: "SEER",
                            value: false,
                        },
                    ]}
                    label="Cooling Efficiency Type"
                    placeholder="Choose Efficiency Type"
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.system.airConditioning.crankcaseHeater`}
                    label="Crankcase Heater"
                    placeholder="0.00"
                    validate={acCrankcaseHeaterValidation}
                    decimals={getDecimalPlaces(`acCrankcaseHeater`)}
                    change={change}
                    disabled={capacityId !== 1}
                    units={{
                        base: getBaseUnits(`acCrankcaseHeater`, modelUnits),
                        options: getUnitOptions("power"),
                        selected: crankcaseHeaterUnits,
                        unitType: "power",
                        accessor: `${accessor}.system.airConditioning.crankcaseHeater_u`,
                    }}
                />
            </InputRow>
        </>
    );
};
