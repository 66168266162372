import React from "react";
import classes from "../style.module.scss";
import { Field } from "redux-form";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";

const evChargerValidation = getValidation("ersResiliencyEvCharger", "v11-13");

export default React.memo(({ accessor, modelUnits, change }) => {
    return (
        <div>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.basementSlabInsulated`}
                    label="Basement slab insulated"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.backwaterValve`}
                    label="Backwater valve with alarm"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.moistureProofCrawlSpace`}
                    label="Moisture-proof crawl space"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.waterproofing`}
                    label="Waterproofing"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="2fr 2fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.roofingMembrane`}
                    label="Adhesive waterproof ice and water barrier underlayment for roofs"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.minR10ContinuousExposedFloors`}
                    label="Minimum R10 continuous for exposed floors"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="2fr 2fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.sumpPump`}
                    label="Sump pump with alarm and battery backup"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.smartThermostats`}
                    label="Programmable, Smart, or Adaptive Thermostats"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="2fr 2fr">
                <Field
                    component={Input}
                    name={`${accessor}.evCharger`}
                    type="number"
                    label="EV Charger (7-digit ENERGY STAR® number)"
                    large
                    className={classes.bottomCheckBox}
                    validate={evChargerValidation}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.elecPanelUpgraded`}
                    label="Electrical Panel Upgrade"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
        </div>
    );
});
