import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import ResiliencyMeasures from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        modelUnits,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResiliencyMeasures);
