import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Specifications from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const isDefaultRoofInputs = selector({ form }, `${accessor}.isDefaultRoofCavity`);
    const { id: wallColourId, value: wallColourValue } = selector({ form }, `${accessor}.wallColour`) || {};
    const { id: roofColourId, value: roofColourValue } = selector({ form }, `${accessor}.roofColour`) || {};
    const { major: h2kMajor, minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const roofCavityGableAreaUnits = selector({ form }, `${accessor}.roofCavity.gableEnds.area_u`);
    const roofCavityGableAreaEquation = selector({ form }, `${accessor}.roofCavity.gableEnds.area_eqn`);

    const { id: gableSheathingMaterialId, value: gableSheathingMaterialRValue } =
        selector({ form }, `${accessor}.roofCavity.gableEnds.sheathingMaterial`) || {};
    const gableSheathingMaterialRValueUnits = selector(
        { form },
        `${accessor}.roofCavity.gableEnds.sheathingMaterial_u`
    );
    const { id: gableExteriorMaterialId, value: gableExteriorMaterialRValue } =
        selector({ form }, `${accessor}.roofCavity.gableEnds.exteriorMaterial`) || {};
    const gableExteriorMaterialRValueUnits = selector({ form }, `${accessor}.roofCavity.gableEnds.exteriorMaterial_u`);

    const roofCavitySlopedAreaUnits = selector({ form }, `${accessor}.roofCavity.slopedRoof.area_u`);
    const roofCavitySlopedAreaEquation = selector({ form }, `${accessor}.roofCavity.slopedRoof.area_eqn`);

    const { id: slopedSheathingMaterialId, value: slopedSheathingMaterialRValue } =
        selector({ form }, `${accessor}.roofCavity.slopedRoof.sheathingMaterial`) || {};
    const slopedSheathingMaterialRValueUnits = selector(
        { form },
        `${accessor}.roofCavity.slopedRoof.sheathingMaterial_u`
    );
    const { id: slopedRoofingMaterialId, value: slopedRoofingMaterialRValue } =
        selector({ form }, `${accessor}.roofCavity.slopedRoof.roofingMaterial`) || {};
    const slopedRoofingMaterialRValueUnits = selector({ form }, `${accessor}.roofCavity.slopedRoof.roofingMaterial_u`);

    const roofCavityVolumeUnits = selector({ form }, `${accessor}.roofCavity.volume_u`);
    const roofCavityVolumeEquation = selector({ form }, `${accessor}.roofCavity.volume_eqn`);

    return {
        accessor,
        isDefaultRoofInputs,
        h2kVersion: `v${h2kMajor}-${h2kMinor}`,
        h2kMinor,
        wallColourId,
        roofColourId,
        wallColourValue,
        roofColourValue,
        modelUnits,
        roofCavityGableAreaUnits,
        roofCavityGableAreaEquation,
        gableSheathingMaterialRValue,
        gableExteriorMaterialRValue,
        gableSheathingMaterialId,
        gableExteriorMaterialId,
        gableSheathingMaterialRValueUnits,
        gableExteriorMaterialRValueUnits,
        roofCavitySlopedAreaUnits,
        roofCavitySlopedAreaEquation,
        slopedSheathingMaterialRValue,
        slopedRoofingMaterialRValue,
        slopedSheathingMaterialId,
        slopedRoofingMaterialId,
        slopedSheathingMaterialRValueUnits,
        slopedRoofingMaterialRValueUnits,
        roofCavityVolumeUnits,
        roofCavityVolumeEquation,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Specifications);
